import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { IonicBaseComponent } from '@front-end/core/ionic-hooks';
import { QuoteStatusEnum, UserSummaryMacroItems } from '@libs/core/api';
import {
  DateOptionEnum,
  QuoteStatusFilterEnum,
} from '../quotes-filters/quotes-filters.component';
import { CheckboxListOption } from '../checkbox-list/abstract-checkbox-list.component';

export interface QuoteFiltersConfiguration {
  toggle: boolean;
  status: boolean;
  date: boolean;
  caporeparti: boolean;
}

@Component({
  selector: 'ui-quotes-filters-modal',
  templateUrl: './quotes-filters-modal.component.html',
  styleUrls: ['./quotes-filters-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotesFiltersModalComponent extends IonicBaseComponent {
  readonly statusOptions = [
    {
      label: 'quote.filters.fields.status.title',
      options: [
        {
          label: 'quote.filters.fields.status.all',
          value: undefined,
        },
        {
          label: 'quote.filters.fields.status.waitingForApproval',
          value: QuoteStatusFilterEnum.WaitingForApproval,
        },
        {
          label: 'quote.filters.fields.status.approved',
          value: QuoteStatusFilterEnum.Approved,
        },
        {
          label: 'quote.filters.fields.status.rejected',
          value: QuoteStatusFilterEnum.Rejected,
        },
      ],
    },
  ];

  readonly dateOptions = [
    {
      label: 'quote.filters.fields.date.title',
      options: [
        {
          label: 'quote.filters.fields.date.lastWeek',
          value: DateOptionEnum.LAST_WEEK,
        },
        {
          label: 'quote.filters.fields.date.lastMonth',
          value: DateOptionEnum.LAST_MONTH,
        },
        {
          label: 'quote.filters.fields.date.lastSemester',
          value: DateOptionEnum.LAST_SEMESTER,
        },
        {
          label: 'quote.filters.fields.date.all',
          value: undefined,
        },
      ],
    },
  ];

  readonly form = this.formBuilder.group({
    status: this.formBuilder.control<(QuoteStatusEnum | undefined)[]>([
      undefined,
    ]),
    date: this.formBuilder.control<(DateOptionEnum | undefined)[]>([
      DateOptionEnum.LAST_WEEK,
    ]),
    caporeparti: this.formBuilder.control<
      (UserSummaryMacroItems | undefined)[]
    >([undefined]),
  });

  _capoRepartiOptions: CheckboxListOption[] = [];
  @Input() set capoReparti(value: UserSummaryMacroItems[]) {
    const options = [
      {
        label: 'quote.filters.fields.capoReparto.all',
        value: undefined,
      },
      ...value.map((user) => ({
        label: `${user.name} (${user.budgetMacroItems
          ?.map((budget) => budget.name)
          .join(', ')})`,
        value: user.id,
      })),
    ];

    this._capoRepartiOptions = [
      {
        label: 'quote.filters.fields.capoReparto.title',
        options: options,
      },
    ];
  }
  @Input() set filters(value: {
    status: (QuoteStatusEnum | undefined)[];
    date: (DateOptionEnum | undefined)[];
    caporeparti: (UserSummaryMacroItems | undefined)[];
  }) {
    this.form.patchValue(value, { emitEvent: false });
  }

  @Input() configuration!: QuoteFiltersConfiguration;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  confirm(): void {
    this.modalController.dismiss(this.form.value);
  }

  close(): void {
    this.modalController.dismiss();
  }
}
