import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BudgetItem, BudgetItemSummary } from '@libs/core/api';

@Component({
  selector: 'ui-budget-amounts',
  templateUrl: './budget-amounts.component.html',
  styleUrls: ['./budget-amounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetAmountsComponent {
  @Input() budgetItem!: BudgetItemSummary | BudgetItem;
}
