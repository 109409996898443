import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixBackendPrice',
})
export class FixBackendPricePipe implements PipeTransform {
  transform(value: number | undefined): number | undefined {
    if (value != null) {
      return value / 100;
    }
    return value;
  }
}
