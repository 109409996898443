import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export type Event = { event: EventType; value: unknown };

export type EventType = 'quote-deleted' | 'quote-updated';

@Injectable({
  providedIn: 'root',
})
export class EventManagerService {
  private eventSubject$ = new Subject<Event>();

  observeStateChanges(...events: EventType[]): Observable<Event> {
    return this.eventSubject$
      .asObservable()
      .pipe(filter((d) => events.length === 0 || events.includes(d.event)));
  }

  notifyStateChange(event: EventType, value?: unknown): void {
    this.eventSubject$.next({ event: event, value: value });
  }
}
