import { Injectable } from '@angular/core';
import { RoleEnum } from '@libs/core/api';

@Injectable({
  providedIn: 'root',
})
export class ApiFiltersService {
  getUserRoleFilter(role: RoleEnum): string {
    return `role eq '${role}'`;
  }

  getQuotesFilter(
    createdBy: number | undefined,
    createdAt: Date | undefined,
    macroItems: number[]
  ): string | undefined {
    const filters: string[] = [];

    if (createdBy) {
      filters.push(`createdBy/id eq ${createdBy}`);
    }

    if (createdAt) {
      filters.push(`createdAt ge ${this.translateDateToApiFormat(createdAt)}`);
    }

    if (macroItems && macroItems.length > 0) {
      const macroItemsFilters = macroItems
        .map((item) => `assigneeId eq ${item}`)
        .join(' or ');
      filters.push(`(${macroItemsFilters})`);
    }

    if (filters.length == 0) {
      return undefined;
    }

    return filters.join(' and ');
  }

  private translateDateToApiFormat(date: Date): string {
    const isoFormat = date.toISOString();
    return `${isoFormat.slice(0, isoFormat.indexOf('.'))}+00:00`;
  }
}
