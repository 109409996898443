import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserSummaryMacroItems } from '@libs/core/api';
import { CheckboxListOption } from '../checkbox-list/abstract-checkbox-list.component';

interface CaporepartoForm {
  caporeparto: FormControl<(UserSummaryMacroItems | undefined)[]>;
}

function buildCaporepartoForm(
  caporeparto?: UserSummaryMacroItems
): FormGroup<CaporepartoForm> {
  return new FormBuilder().group<CaporepartoForm>({
    caporeparto: new FormControl(caporeparto ? [caporeparto] : [], {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });
}

@Component({
  selector: 'ui-select-caporeparto-modal',
  templateUrl: './select-caporeparto-modal.component.html',
  styleUrls: ['./select-caporeparto-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCaporepartoModalComponent {
  @Input() set capireparto(capireparto: UserSummaryMacroItems[]) {
    this.options = [
      {
        label: '',
        options: capireparto.map((caporeparto) => ({
          label: `${caporeparto.name}`,
          value: caporeparto,
        })),
      },
    ];
  }
  @Input() caporeparto!: UserSummaryMacroItems;
  options: CheckboxListOption[] = [];

  readonly form = buildCaporepartoForm(this.caporeparto);

  constructor(private modalController: ModalController) {}

  back() {
    this.modalController.dismiss();
  }

  apply(form: FormGroup<CaporepartoForm>) {
    if (form.valid) {
      this.modalController.dismiss(
        form.value.caporeparto && form.value.caporeparto[0]
      );
    }
  }
}
