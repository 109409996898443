import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ShowPasswordInputComponent } from './components/show-password-input/show-password-input.component';
import { UserInitialsPipe } from './pipes/user-initials.pipe';
import { CostCentersListComponent } from './components/cost-centers-list/cost-centers-list.component';
import { MediaPreviewComponent } from './components/media-preview/media-preview.component';
import { CurrentCdcComponent } from './components/current-cdc/current-cdc.component';
import { QuoteStatusBadgeComponent } from './components/quote-status-badge/quote-status-badge.component';
import { QuoteStatusColorDirective } from './directives/quote-status-color.directive';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UserProfileImageComponent } from './components/user-profile-image/user-profile-image.component';
import { QuoteOptionsPreviewComponent } from './components/quote-options-preview/quote-options-preview.component';
import { QuotePreviewComponent } from './components/quote-preview/quote-preview.component';
import { BudgetBadgeComponent } from './components/budget-badge/budget-badge.component';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { QuoteListComponent } from './components/quote-list/quote-list.component';
import { NgxAuthUtilsModule } from 'ngx-auth-utils';
import { DraftQuotePreviewComponent } from './components/draft-quote-preview/draft-quote-preview.component';
import { QuoteOptionsTabComponent } from './components/quote-options-tab/quote-options-tab.component';
import { ImagePreviewModalComponent } from './components/image-preview-modal/image-preview-modal.component';
import { QuotesFiltersComponent } from './components/quotes-filters/quotes-filters.component';
import { QuotesFiltersModalComponent } from './components/quotes-filters-modal/quotes-filters-modal.component';
import { SimpleCheckboxListComponent } from './components/checkbox-list/simple-checkbox-list/simple-checkbox-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreUtilsModule } from '@front-end/core/utils';
import { SelectCaporepartoComponent } from './components/select-caporeparto/select-caporeparto.component';
import { SelectCaporepartoModalComponent } from './components/select-caporeparto-modal/select-caporeparto-modal.component';
import { QuoteDetailComponent } from './components/quote-detail/quote-detail.component';
import { QuoteDetailModalComponent } from './components/quote-detail-modal/quote-detail-modal.component';
import { QuoteDetailOptionComponent } from './components/quote-detail-option/quote-detail-option.component';
import { ResponsabileQuoteDetailModalComponent } from './components/responsabile-quote-detail-modal/responsabile-quote-detail-modal.component';
import { BudgetItemSelectComponent } from './components/budget-item-select/budget-item-select.component';
import { BudgetItemSelectModalComponent } from './components/budget-item-select-modal/budget-item-select-modal.component';
import { CaporepartoQuoteDetailModalComponent } from './components/caporeparto-quote-detail-modal/caporeparto-quote-detail-modal.component';
import { BudgetAmountsComponent } from './components/budget-amounts/budget-amounts.component';
import { FixBackendPricePipe } from './pipes/fix-backend-price.pipe';
import { ApproveRejectToggleButtonsComponent } from './components/approve-reject-toggle-buttons/approve-reject-toggle-buttons.component';
import { SupervisoreQuoteDetailModalComponent } from './components/supervisore-quote-detail-modal/supervisore-quote-detail-modal.component';
import { AccordionCheckboxListComponent } from './components/checkbox-list/accordion-checkbox-list/accordion-checkbox-list.component';
import { FixBackendDatePipe } from './pipes/fix-backend-date.pipe';
import { RolePipe } from './pipes/role.pipe';
import { SupervisoreHandledQuoteDetailModalComponent } from './components/supervisore-handled-quote-detail-modal/supervisore-handled-quote-detail-modal.component';
import { SwiperModule } from 'swiper/angular';
import { ImageSliderModalComponent } from './components/image-slider-modal/image-slider-modal.component';
import { SliderItemComponent } from './components/slider-item/slider-item.component';
import { QuoteHistoryModalComponent } from './components/quote-history-modal/quote-history-modal.component';
import { StatusHistoryPipe } from './pipes/status-history.pipe';
import { SelectMacroItemModalComponent } from './components/select-macroitem-modal/select-macroitem-modal.component';
import { SelectMacroItemComponent } from './components/select-macroitem/select-macroitem.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    RouterModule,
    NgxAuthUtilsModule,
    ReactiveFormsModule,
    CoreUtilsModule,
    SwiperModule,
    PdfViewerModule,
  ],
  declarations: [
    FormFieldComponent,
    ShowPasswordInputComponent,
    UserInitialsPipe,
    CostCentersListComponent,
    MediaPreviewComponent,
    CurrentCdcComponent,
    QuoteStatusBadgeComponent,
    QuoteStatusColorDirective,
    ToolbarComponent,
    UserProfileImageComponent,
    QuoteOptionsPreviewComponent,
    QuotePreviewComponent,
    BudgetBadgeComponent,
    RelativeDatePipe,
    QuoteListComponent,
    DraftQuotePreviewComponent,
    QuoteOptionsTabComponent,
    ImagePreviewModalComponent,
    QuotesFiltersComponent,
    QuotesFiltersModalComponent,
    SimpleCheckboxListComponent,
    AccordionCheckboxListComponent,
    SelectCaporepartoComponent,
    SelectCaporepartoModalComponent,
    QuoteDetailModalComponent,
    QuoteDetailComponent,
    QuoteDetailOptionComponent,
    ResponsabileQuoteDetailModalComponent,
    BudgetItemSelectComponent,
    BudgetItemSelectModalComponent,
    BudgetAmountsComponent,
    FixBackendPricePipe,
    CaporepartoQuoteDetailModalComponent,
    ApproveRejectToggleButtonsComponent,
    SupervisoreQuoteDetailModalComponent,
    FixBackendDatePipe,
    RolePipe,
    SupervisoreHandledQuoteDetailModalComponent,
    ImageSliderModalComponent,
    SliderItemComponent,
    QuoteHistoryModalComponent,
    StatusHistoryPipe,
    SelectMacroItemComponent,
    SelectMacroItemModalComponent,
  ],
  exports: [
    FormFieldComponent,
    ShowPasswordInputComponent,
    UserInitialsPipe,
    CostCentersListComponent,
    MediaPreviewComponent,
    CurrentCdcComponent,
    QuoteStatusBadgeComponent,
    QuoteStatusColorDirective,
    ToolbarComponent,
    UserProfileImageComponent,
    QuoteOptionsPreviewComponent,
    QuotePreviewComponent,
    RelativeDatePipe,
    QuoteListComponent,
    DraftQuotePreviewComponent,
    QuoteOptionsTabComponent,
    QuotesFiltersComponent,
    SimpleCheckboxListComponent,
    AccordionCheckboxListComponent,
    SelectCaporepartoComponent,
    QuoteDetailModalComponent,
    QuoteDetailComponent,
    QuoteDetailOptionComponent,
    ResponsabileQuoteDetailModalComponent,
    FixBackendPricePipe,
    CaporepartoQuoteDetailModalComponent,
    BudgetItemSelectComponent,
    BudgetItemSelectComponent,
    BudgetAmountsComponent,
    SupervisoreQuoteDetailModalComponent,
    FixBackendDatePipe,
    RolePipe,
    SupervisoreHandledQuoteDetailModalComponent,
    StatusHistoryPipe,
    SelectMacroItemComponent,
    SelectMacroItemModalComponent,
  ],
  providers: [],
})
export class UikitModule {}
