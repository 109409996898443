<front-end-insert-confirm-screen
  data-cy="Sent quote"
  icon="paper-plane-outline"
  [title]="'pages.insert.sent.title' | translate"
  [subtitle]="'pages.insert.sent.subtitle' | translate"
  [action]="'pages.insert.sent.action' | translate"
  actionId="See sent quotes"
  (actionClick)="close()"
>
</front-end-insert-confirm-screen>
