import { Injectable } from '@angular/core';
import {
  QuoteFilters,
  QuoteStatusFilterEnum,
} from '../components/quotes-filters/quotes-filters.component';
import { QuoteStatusEnum } from '@libs/core/api';

@Injectable({
  providedIn: 'root',
})
export class StatusFilterHelperService {
  translateStatusFilters(
    allStatus: QuoteStatusEnum[],
    filters?: QuoteFilters
  ): QuoteStatusEnum[] | undefined {
    const filtersStatus =
      (filters?.status ?? []).filter((item) => item != null).length > 0
        ? filters?.status?.filter((item) => item != null)
        : allStatus;

    return (filtersStatus as QuoteStatusFilterEnum[]).flatMap(
      (status: QuoteStatusFilterEnum) =>
        this.translateQuoteStatusFromQuoteStatusFilter(status)
    );
  }

  protected translateQuoteStatusFromQuoteStatusFilter(
    status: QuoteStatusFilterEnum
  ): QuoteStatusEnum[] {
    switch (status) {
      case QuoteStatusFilterEnum.Draft:
        return [QuoteStatusEnum.Draft];
      case QuoteStatusFilterEnum.WaitingForApproval:
        return [
          QuoteStatusEnum.WaitingForPreApproval,
          QuoteStatusEnum.PreApproved,
          QuoteStatusEnum.ApprovedOverBudget,
        ];
      case QuoteStatusFilterEnum.Approved:
        return [QuoteStatusEnum.Approved, QuoteStatusEnum.OverBudgetAccepted];
      case QuoteStatusFilterEnum.Rejected:
        return [
          QuoteStatusEnum.Rejected,
          QuoteStatusEnum.PreRejected,
          QuoteStatusEnum.OverBudgetRejected,
        ];
    }
    return [];
  }
}
