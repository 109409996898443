import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Quote, QuoteHistory, QuoteService } from '@libs/core/api';
import { ModalController } from '@ionic/angular';
import { first, Observable, switchMap } from 'rxjs';
import { CostCentersService } from '@front-end/core/services';
import { getValueOrError } from '@front-end/core/utils';

@Component({
  selector: 'ui-quote-history-modal',
  templateUrl: './quote-history-modal.component.html',
  styleUrls: ['./quote-history-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteHistoryModalComponent {
  private _quote?: Quote;
  quoteHistory$?: Observable<Array<QuoteHistory>>;

  readonly costCenter$ = this.costCentersService.selectedCostCenter$.pipe(
    first()
  );

  @Input() set quote(quote: Quote) {
    this._quote = quote;
    this.quoteHistory$ = this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService.getQuoteHistory({
          id: getValueOrError(costCenter.id),
          quoteId: getValueOrError(quote.id),
        })
      )
    );
  }

  constructor(
    private modalController: ModalController,
    private quoteService: QuoteService,
    private costCentersService: CostCentersService
  ) {}

  cancel() {
    this.modalController.dismiss(undefined, 'cancel');
  }
}
