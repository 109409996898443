/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserBudgetLimit } from '../models/user-budget-limit';
import { UserSummaryMacroItems } from '../models/user-summary-macro-items';

/**
 * User info
 */
@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation costCenterUserList
   */
  static readonly CostCenterUserListPath = '/api/mobile/costcenter/{id}/users/';

  /**
   * Get users by cost center.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterUserList()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterUserList$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * Filter
     */
    $filter?: string;

    /**
     * Filter by my micro items
     */
    $myMacroItems?: boolean;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<UserSummaryMacroItems>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.CostCenterUserListPath,
      'get'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.query('$filter', params['$filter'], {});
      rb.query('$myMacroItems', params['$myMacroItems'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UserSummaryMacroItems>>;
        })
      );
  }

  /**
   * Get users by cost center.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `costCenterUserList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterUserList(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * Filter
     */
    $filter?: string;

    /**
     * Filter by my micro items
     */
    $myMacroItems?: boolean;
    context?: HttpContext;
  }): Observable<Array<UserSummaryMacroItems>> {
    return this.costCenterUserList$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<UserSummaryMacroItems>>) =>
          r.body as Array<UserSummaryMacroItems>
      )
    );
  }

  /**
   * Path part for operation costCenterResponsabileBudgetLimit
   */
  static readonly CostCenterResponsabileBudgetLimitPath =
    '/api/mobile/costcenter/{id}/budget-limit';

  /**
   * Get budget limit of responsabile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `costCenterResponsabileBudgetLimit()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterResponsabileBudgetLimit$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<UserBudgetLimit>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.CostCenterResponsabileBudgetLimitPath,
      'get'
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserBudgetLimit>;
        })
      );
  }

  /**
   * Get budget limit of responsabile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `costCenterResponsabileBudgetLimit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  costCenterResponsabileBudgetLimit(params: {
    /**
     * The id of the cost center
     */
    id: number;
    context?: HttpContext;
  }): Observable<UserBudgetLimit> {
    return this.costCenterResponsabileBudgetLimit$Response(params).pipe(
      map((r: StrictHttpResponse<UserBudgetLimit>) => r.body as UserBudgetLimit)
    );
  }
}
