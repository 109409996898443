import { Injectable } from '@angular/core';

import {
  ActionPerformed,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import {
  CompanySummary,
  CostCenterSummary,
  NotificationsService,
  QuoteService,
  QuoteStatusEnum,
} from '@libs/core/api';
import { NgxAuthService } from 'ngx-auth-utils';
import { QuotesCreationService } from './quotes-creation.service';
import {
  CostCentersService,
  EventManagerService,
  LocalStorageService,
} from '@front-end/core/services';
import { Capacitor } from '@capacitor/core';
import { ToastService } from '@libs/ui/uikit';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private notificationsService: NotificationsService,
    private ngxAuthService: NgxAuthService,
    private quoteService: QuoteService,
    private quoteCreationService: QuotesCreationService,
    private localStorageService: LocalStorageService,
    private costCentersService: CostCentersService,
    private eventManagerService: EventManagerService,
    private toastService: ToastService
  ) {}

  private readonly pushTokenStorageKey = 'teleconsulting-stp--push-token';

  public setupPushNotifications(): void {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        this.toastService.error('notifications.permissionDenied');
      }
    });

    PushNotifications.removeAllListeners();

    // If there is some issue with the setup and push will not work
    PushNotifications.addListener('registrationError', () => {
      this.toastService.error('notifications.registrationError');
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      const savedPushToken = this.localStorageService.getItem(
        this.pushTokenStorageKey
      );

      if (savedPushToken === token.value) {
        return;
      }

      this.localStorageService.setItem(this.pushTokenStorageKey, token.value);
      this.notificationsService
        .addNotificationToken({
          body: {
            token: token.value,
          },
        })
        .subscribe();
    });

    // Show the notification payload if the app is open on our device
    // PushNotifications.addListener(
    //   'pushNotificationReceived',
    //   (notification: PushNotificationSchema) => {
    //     alert('Push received: ' + JSON.stringify(notification));
    //   }
    // );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        const costCenterToFind = Number(
          notification.notification.data['costCenterId']
        );
        const quoteId = Number(notification.notification.data['quoteId']);

        const companies = this.ngxAuthService.snapshot.user?.companies;

        const costCenter = companies
          ?.map((company: CompanySummary) => company.costCenters)
          .flat()
          .find(
            (costCenter: CostCenterSummary) =>
              costCenter.id === costCenterToFind
          );

        if (!costCenter) {
          return;
        }

        this.quoteService
          .quoteRetrive({ id: costCenter.id, quoteId: quoteId })
          .subscribe((quote) => {
            this.costCentersService.selectCostCenter(costCenter);
            let modal$: Observable<unknown>;
            switch (quote.status) {
              case QuoteStatusEnum.WaitingForPreApproval:
                modal$ =
                  this.quoteCreationService.caporepartoPreApproveQuote(quote);
                break;
              case QuoteStatusEnum.PreApproved:
                modal$ = this.quoteCreationService.approveOrRejectQuote(quote);
                break;
              case QuoteStatusEnum.ApprovedOverBudget:
                modal$ =
                  this.quoteCreationService.supervisoreManageOverbudget(quote);
                break;
              case QuoteStatusEnum.OverBudgetRejected:
              case QuoteStatusEnum.OverBudgetAccepted:
                modal$ =
                  this.quoteCreationService.openQuoteDetailsShowingOverBudgetAmount(
                    quote
                  );
                break;
              default:
                modal$ = this.quoteCreationService.openQuoteDetails(quote);
                break;
            }
            if (modal$) {
              modal$.subscribe(() => {
                this.eventManagerService.notifyStateChange('quote-updated');
              });
            }
          });
      }
    );
  }

  public removePushNotificationToken(): void {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    const token = this.localStorageService.getItem(this.pushTokenStorageKey);
    if (token) {
      this.notificationsService
        .deleteNotificationToken({ body: { token } })
        .subscribe();
    }
    this.localStorageService.removeItem(this.pushTokenStorageKey);
    PushNotifications.unregister();
  }
}
