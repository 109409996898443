<ion-header mode="ios">
  <ion-toolbar color="secondary">
    <ion-title class="text-grey-ultra-dark">{{ 'pages.quotes.detail.title' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button [attr.aria-label]="'pages.quotes.modal.cancel' | translate" (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ui-current-cdc [costCenter]="costCenter"></ui-current-cdc>
</ion-header>

<ion-content class="ion-padding">
  <ui-quote-detail #quoteDetails [identity]="identity" [detail]="quote" [showStatus]="false" [caporepartoApprovals]="caporepartoApprovals">
  </ui-quote-detail>

  <form *appVar="this.form?.controls?.options?.at(quoteDetails.optionNumber - 1) as option" [formGroup]="option">
    <ui-form-field [control]="option.controls.comment" class="fs-16" lines="full" label="pages.approvals.comment">
      <ion-input type="text" [formControl]="option.controls.comment" data-cy="Comment"></ion-input>
    </ui-form-field>

    <ui-approve-reject-toggle-buttons
      class="mt-20"
      [approved]="option.controls.approved.value"
      [approveText]="'pages.approvals.approveOption' | translate: { option: 'OPT ' + quoteDetails.optionNumber }"
      [rejectText]="'pages.approvals.rejectOption' | translate: { option: 'OPT ' + quoteDetails.optionNumber }"
      (approvalResult)="onApprovalResult($event, option, quoteDetails.optionNumber - 1)"
    >
      >
    </ui-approve-reject-toggle-buttons>
  </form>
</ion-content>

<ion-footer class="ion-no-border external-page-footer" mode="ios">
  <ion-toolbar>
    <p class="text-center fs-16">{{ optionsToApprove }} {{ 'pages.approvals.optionsToHandle' | translate }}</p>
    <ion-buttons>
      <ion-button color="primary" expand="block" fill="solid" data-cy="Confirm option" (click)="confirm()">
        {{ 'pages.approvals.confirm' | translate }}</ion-button
      >
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
