import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { FilesService, UploadedFile } from '@libs/core/api';
import { startWith, switchMap } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';

export interface FileData {
  type: 'image' | 'document';
  data: string;
  downloadable: boolean;
}

export interface DBUploadedFile {
  uuid: string;
  data: string;
  timestamp: number;
}

@Injectable({
  providedIn: 'root',
})
export class UploadedFilesService {
  readonly cacheRetentionMillis = 30 * 24 * 60 * 60 * 1000; // One month
  readonly cacheSizeThreshold = 1000; // Start deleting old images when we have at least this number of cached ones
  readonly renewEntriesTimestamp = false;

  readonly loadingFile: FileData = {
    type: 'image',
    data: 'assets/images/loader.gif',
    downloadable: false,
  };

  constructor(
    private filesService: FilesService,
    private dbService: NgxIndexedDBService
  ) {}

  public getFileData$(fileOrUuid: string): Observable<FileData> {
    if (!fileOrUuid) {
      throw new Error('not-a-file-or-uuid');
    }

    if (this.isUuid(fileOrUuid)) {
      return this.getRemoteFile(fileOrUuid);
    }

    return of(this.createFileData(fileOrUuid));
  }

  private getRemoteFile(fileOrUuid: string) {
    return this.dbService.getByIndex('uploaded-files', 'uuid', fileOrUuid).pipe(
      switchMap((imageData) => {
        if (imageData != null) {
          const casted = imageData as DBUploadedFile;
          return this.renewEntry(casted).pipe(
            map((renewedData) => this.createFileData(renewedData.data))
          );
        }
        return this.filesService.getUploadedFile({ uuid: fileOrUuid }).pipe(
          this.cacheUploadedFile(),
          map((uploadedFile) => this.createFileData(uploadedFile.data))
        );
      }),
      startWith(this.loadingFile)
    );
  }

  private createFileData(data: string): FileData {
    return {
      type: this.getFileType(data),
      data: data,
      downloadable: true,
    };
  }

  isUuid(value: string): boolean {
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return value.length === 36 && regexExp.test(value);
  }

  private getFileType(data: string) {
    return data.includes('image') ? 'image' : 'document';
  }

  private cacheUploadedFile(): (
    source: Observable<UploadedFile>
  ) => Observable<UploadedFile> {
    return switchMap((freshFile) =>
      this.dbService
        .add('uploaded-files', {
          uuid: freshFile.uuid,
          data: freshFile.data,
          timestamp: Date.now(),
        })
        .pipe(
          this.clearExpiredCacheEntries(),
          map(() => freshFile)
        )
    );
  }

  private clearExpiredCacheEntries(): (
    source: Observable<UploadedFile>
  ) => Observable<UploadedFile> {
    return switchMap((data) =>
      this.dbService.count('uploaded-files').pipe(
        switchMap((count) => {
          // No need to clear cache: still under the threshold
          if (count <= this.cacheSizeThreshold) {
            return of(data);
          }

          const keyRange = IDBKeyRange.upperBound(
            Date.now() - this.cacheRetentionMillis
          );
          return this.dbService
            .getAllKeysByIndex('uploaded-files', 'timestamps', keyRange)
            .pipe(
              switchMap((expiredEntries) => {
                const entriesToDelete = expiredEntries
                  .map((entry) => entry.primaryKey)
                  .slice(0, count - this.cacheSizeThreshold);
                return this.dbService.bulkDelete(
                  'uploaded-files',
                  entriesToDelete
                );
              }),
              map(() => data)
            );
        })
      )
    );
  }

  private renewEntry(fileData: DBUploadedFile): Observable<DBUploadedFile> {
    if (!this.renewEntriesTimestamp) {
      return of(fileData);
    }
    fileData.timestamp = Date.now();
    return this.dbService.update('uploaded-files', fileData);
  }
}
