import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, from, of, switchMap } from 'rxjs';
import { Intent, SendIntent } from 'send-intent';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class SendIntentService {
  private readonly enabled = Capacitor.isNativePlatform();
  private readonly intent: BehaviorSubject<Intent | null> =
    new BehaviorSubject<Intent | null>(null);

  addIntent(intent: Intent): void {
    this.intent.next(intent);
  }

  clearIntent(): void {
    this.intent.next(null);
  }

  getIntent(): Intent | null {
    return this.intent.value;
  }

  getIntents(): Intent[] {
    const intent = this.getIntent();
    const additionalIntents = intent?.additionalItems ?? [];
    const intents = [intent, ...additionalIntents]
      .filter(Boolean)
      .filter((intent) => this.filterIntent(intent))
      .map((intent) => this.mapIntent(intent));

    return intents;
  }

  hasIntent(): boolean {
    return this.intent.value != null;
  }

  checkSendIntentReceived(): Observable<Intent | null> {
    return of(this.enabled).pipe(
      filter((enabled) => enabled),
      switchMap(() => from(SendIntent.checkSendIntentReceived()))
      // finalize(() => SendIntent.finish()), https://github.com/carsten-klaffke/send-intent/issues/69
    );
  }

  private filterIntent(intent: Intent): boolean {
    return !!intent.title && !!intent.type && !!intent.url;
  }

  private mapIntent(intent: Intent): Intent {
    return {
      ...intent,
      url: intent.url ? decodeURIComponent(intent.url) : undefined,
      type: intent.type ? decodeURIComponent(intent.type) : undefined,
    };
  }
}
