import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appVar]',
})
export class VarDirective {
  @Input()
  set appVar(context: unknown) {
    this.context.$implicit = this.context.appVar = context;

    if (!this.hasView) {
      this.vcRef.createEmbeddedView(this.templateRef, this.context);
      this.hasView = true;
    }
  }

  private context: {
    $implicit: unknown;
    appVar: unknown;
  } = {
    $implicit: null,
    appVar: null,
  };

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private vcRef: ViewContainerRef
  ) {}
}
