<div class="filters px-16" *appVar="formUpdate$ | async" data-cy="Quote filters">
  <ion-item class="ion-no-padding" lines="none">
    <ng-container *ngIf="configuration.toggle">
      <ion-label class="fs-13 font-medium filters-text">{{ toggleLabel | translate }}</ion-label>
      <ion-toggle slot="start" [formControl]="form.controls.toggle" data-cy="Owned quotes filter"></ion-toggle>
    </ng-container>
  </ion-item>
  <ion-button fill="clear" class="fs-13 filters-text" (click)="openFiltersModal()" data-cy="Open quotes filters modal" mode="ios">
    <ion-icon slot="start" type="" name="funnel"></ion-icon>
    {{ 'quote.filters.filter' | translate }}
  </ion-button>
</div>
