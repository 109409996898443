<div class="flex ion-justify-content-between fs-14 font-medium">
  <div>
    <div class="label">{{ 'pages.approvals.details.totalBudget' | translate }}</div>
    <div class="value" data-cy="Item budget total amount">{{ budgetItem.totalBudget | fixBackendPrice | currency: 'EUR' }}</div>
  </div>
  <div>
    <div class="label">{{ 'pages.approvals.details.approvedBudget' | translate }}</div>
    <div class="value" data-cy="Item budget used amount">{{ budgetItem.usedBudget | fixBackendPrice | currency: 'EUR' }}</div>
  </div>
  <div>
    <div class="label">{{ 'pages.approvals.details.residualBudget' | translate }}</div>
    <div class="value" data-cy="Item budget residual amount">{{ budgetItem.residualBudget | fixBackendPrice | currency: 'EUR' }}</div>
  </div>
</div>
