<ion-app data-cy="Shell">
  <ion-menu contentId="main-content" menu-id="sidebar" type="overlay" ngxAuthUser #loggedUser="userRef">
    <ion-header class="ion-padding" mode="ios">
      <ion-toolbar color="white">
        <ion-buttons slot="end">
          <ion-button (click)="closeMenu()" data-cy="Close menu" mode="ios">
            <ion-ripple-effect role="presentation" class="md unbounded hydrated"></ion-ripple-effect>
            <ion-icon slot="icon-only" color="primary" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <div class="grid grid-menu gap-8">
        <ui-user-profile-image class="avatar" [user]="loggedUser.user" [size]="50"></ui-user-profile-image>

        <div class="flex flex-column gap-4 pt-6 justify-content-center info">
          <div class="fs-16 font-medium">{{ loggedUser.user?.fullName }}</div>
          <div class="fs-15 font-regular">{{ loggedUser.user?.email }}</div>
          <div *ngxAuth="true" class="fs-15 font-regular">{{ role$ | async | role: false:(superOperatore$ | async) }}</div>
        </div>

        <a class="fs-15 font-medium logout pt-6" (click)="logout()" data-cy="Log out">{{ 'sidebar.logout' | translate }}</a>
      </div>
    </ion-header>
    <ion-content>
      <div class="my-8 fs-16 font-medium ion-padding" data-cy="Change cost center">{{ 'sidebar.changeCDC' | translate }}</div>
      <ui-cost-centers-list [companies]="loggedUser.user?.companies" (selected)="selectCostCenter($event)"></ui-cost-centers-list>
    </ion-content>

    <ion-footer class="ion-padding" mode="ios">
      <div class="text-center w-full" [translate]="'sidebar.appVersion'" [translateParams]="{ version: appVersion }"></div>
      <div class="text-center w-full" [translate]="'sidebar.copyright'" [translateParams]="{ year: currentYear }"></div>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="main-content" data-cy="Content"></ion-router-outlet>
</ion-app>
