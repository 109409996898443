import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-budget-badge',
  templateUrl: './budget-badge.component.html',
  styleUrls: ['./budget-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetBadgeComponent {
  @Input() bgClass = 'bg-primary-ultra-light';
}
