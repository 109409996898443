import { Component, ContentChild, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder } from '@angular/forms';

export interface CheckboxListOption {
  label: string;
  options: CheckboxOption[];
}

export interface CheckboxOption {
  label: string;
  value: unknown;
}

@Component({
  template: '',
})
export abstract class AbstractCheckboxListComponent
  implements ControlValueAccessor
{
  @ContentChild(TemplateRef) templateRef?: TemplateRef<unknown>;

  _listOptions: CheckboxListOption[] = [];
  _allOptions: CheckboxOption[] = [];
  _incrementalLengthsOfOptions: number[] = [];

  abstract defaultAllValue: unknown;
  abstract multi: boolean;

  formArray = this.fb.array([]);

  selectedOptions: unknown[] = [];

  touched = false;
  disabled = false;

  constructor(protected fb: FormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = (selectedOptions: unknown[]) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  resetForm(options: CheckboxOption[]): void {
    this.formArray.clear();

    options.forEach((option) => {
      this.formArray.push(
        this.fb.control(this.selectedOptions.includes(option.value))
      );
    });
  }

  updateFormValues(selectedOptions: unknown[]): void {
    this.formArray.controls.forEach((control, index) =>
      control.setValue(selectedOptions.includes(this._allOptions[index].value))
    );
  }

  selectOnly(value: unknown): void {
    this.selectedOptions = [value];
    this.formArray.controls.forEach((control, index) =>
      control.setValue(this._allOptions[index].value === value)
    );
  }

  onCheckboxChange(option: CheckboxOption, event: Event) {
    this.markAsTouched();
    if (!this.disabled) {
      const checked = (event as CustomEvent).detail.checked;
      if (checked) {
        this.addValue(option.value);
      } else {
        this.removeValue(option.value);
      }
      this.onChange(this.selectedOptions);
    }
  }

  protected removeValue(value: unknown): void {
    const hasMoreThanOneSelection = this.selectedOptions.length > 1;
    const hasAllValue =
      this._allOptions.filter((option) => option.value === undefined).length >
      0;

    const index = this.selectedOptions.indexOf(value, 0);
    if (index > -1 && (hasMoreThanOneSelection || hasAllValue)) {
      this.selectedOptions.splice(index, 1);
    }

    if (this.selectedOptions.length === 0) {
      this.selectedOptions = [this.defaultAllValue];
    }

    this.updateFormValues(this.selectedOptions);
  }

  protected addValue(value: unknown): void {
    if (value === this.defaultAllValue) {
      this.selectOnly(this.defaultAllValue);
    } else {
      if (this.selectedOptions.includes(value)) {
        return;
      }

      if (!this.multi) {
        this.selectOnly(value);
      } else if (this.selectedOptions.includes(this.defaultAllValue)) {
        this.selectedOptions.push(value);
        this.removeValue(this.defaultAllValue);
      } else {
        this.selectedOptions.push(value);
      }
    }
  }

  writeValue(selectedOptions: unknown[]) {
    this.selectedOptions = selectedOptions;
    this.resetForm(this._allOptions);
  }

  registerOnChange(onChange: never) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: never) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  protected computeIncrementalOptionsLengths(
    list: CheckboxListOption[]
  ): number[] {
    const progressiveLengths: number[] = [0];
    list.forEach((options) => {
      const previousLength = progressiveLengths[progressiveLengths.length - 1];
      progressiveLengths.push(previousLength + options.options.length);
    });
    return progressiveLengths;
  }
}
