import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Pagination, Zoom } from 'swiper';

SwiperCore.use([Pagination, Zoom]);

@Component({
  selector: 'ui-image-slider-modal',
  templateUrl: './image-slider-modal.component.html',
  styleUrls: ['./image-slider-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageSliderModalComponent implements AfterContentInit {
  @Input() attachments!: Array<string>;
  @Input() selectedAttachment!: string;
  @ViewChild('swiper') swiper?: SwiperComponent;

  constructor(private modalController: ModalController) {}

  cancel() {
    this.modalController.dismiss();
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.swiper) {
        const index = this.attachments.findIndex(
          (attachment) => attachment === this.selectedAttachment
        );
        this.swiper.swiperRef.slideTo(index);
        this.swiper.swiperRef.zoom.enable();
      }
    }, 0);
  }
}
