import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VarDirective } from './directives/var.directive';

@NgModule({
  declarations: [VarDirective],
  imports: [CommonModule],
  exports: [VarDirective],
  providers: [{ provide: LOCALE_ID, useValue: 'it-IT' }],
})
export class CoreUtilsModule {}
