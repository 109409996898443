import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonicBaseComponent } from '@front-end/core/ionic-hooks';
import { CostCenterSummary } from '@libs/core/api';

@Component({
  selector: 'ui-current-cdc',
  templateUrl: './current-cdc.component.html',
  styleUrls: ['./current-cdc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentCdcComponent extends IonicBaseComponent {
  private _costCenter?: CostCenterSummary | undefined;
  get costCenter(): CostCenterSummary | undefined {
    return this._costCenter;
  }
  @Input() set costCenter(value: CostCenterSummary | undefined) {
    this._costCenter = value;
    this.refreshSuperoperatore();
  }

  private _showSuperOperatore?: boolean;
  get showSuperOperatore(): boolean | undefined {
    return this._showSuperOperatore;
  }

  @Input() set showSuperOperatore(value: boolean | undefined) {
    this._showSuperOperatore = value;
    this.refreshSuperoperatore();
  }

  superOperatore = false;

  refreshSuperoperatore() {
    this.superOperatore = !!(
      this.showSuperOperatore ||
      this.costCenter?.macroItems?.every((m) => m.superOperatore)
    );
  }
}
