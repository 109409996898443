<ion-header class="ion-no-border" mode="ios">
  <ion-toolbar color="light">
    <ion-title class="fs-18 font-medium" color="dark" data-cy="Assignee selection">
      {{ 'pages.insert.selectCaporeparto.title' | translate }}
    </ion-title>

    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form id="select-department-head-form" [formGroup]="form" (ngSubmit)="apply(form)">
    <ui-simple-checkbox-list data-cy="Assignee select" class="mb-16" [options]="options" [formControl]="form.controls.caporeparto">
      <ng-template let-option>
        <div class="flex flex-row align-items-center row-gap-8 py-8">
          <ui-user-profile-image [user]="option.value"></ui-user-profile-image>

          <div class="flex flex-column gap-4">
            <span class="fs-16 font-medium">{{ option.value.name }}</span>

            <span class="text-grey-dark fs-14 font-regular">
              <span *ngFor="let reparto of option.value.budgetMacroItems; let last = last">
                {{ reparto.name }}<span *ngIf="!last">, </span>
              </span>
            </span>
          </div>
        </div>
      </ng-template>
    </ui-simple-checkbox-list>
  </form>
</ion-content>

<ion-footer class="ion-no-border" mode="ios">
  <ion-toolbar color="light">
    <ion-button class="w-full m-0" form="select-department-head-form" type="submit" color="primary" data-cy="Apply selection">
      {{ 'pages.insert.selectCaporeparto.apply' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
