<div class="flex flex-column" data-cy="Quote detail">
  <div class="flex flex-column align-items-start column-gap-8">
    <span class="fs-12 text-grey-dark pt-8" *ngIf="showMacroBudgetItem && macroItem != null">
      {{ 'pages.quotes.detail.macroItem' | translate: { macroItemName: macroItem.name } | uppercase }}
    </span>
    <span class="fs-12 text-grey-dark pt-3" *ngIf="showMacroBudgetItem && quote.budgetItem != null">
      {{ 'pages.quotes.detail.budgetItem' | translate: { name: quote.budgetItem.name } | uppercase }}
    </span>
    <div class="w-full fs-12 text-grey-dark pt-3" *ngIf="showBudgetItemBudget && quote.budgetItem != null">
      <ui-budget-amounts [budgetItem]="quote.budgetItem"></ui-budget-amounts>
    </div>
    <h1 class="m-0 fs-18 font-medium">{{ quote.description }}</h1>
    <span class="fs-14 text-grey-dark font-medium" *ngIf="showCreator">
      {{
        (quote.createdBy?.id === identity.id ? 'pages.quotes.detail.date' : insertLabel)
          | translate
            : {
                date: (showApprovedDate ? quote.approvedAt : quote.createdAt) | fixBackendDate | date,
                time: (showApprovedDate ? quote.approvedAt : quote.createdAt) | fixBackendDate | date: 'shortTime',
                creator: showApprovedDate ? quote.approvedBy?.name : quote.createdBy?.name
              }
      }}
    </span>

    <ion-button class="fs-14" fill="clear" size="small" mode="ios" (click)="openHistory()">
      {{ 'pages.quotes.detail.more' | translate }}
      <ion-icon slot="end" name="chevron-forward-outline" size="small"></ion-icon>
    </ion-button>

    <ui-quote-status-badge *ngIf="showStatus && quote.status" [status]="quote.status"></ui-quote-status-badge>
  </div>
</div>

<ng-content select="[macroItem]"></ng-content>

<ui-quote-options-tab
  *ngIf="(quote.options || []).length > 1"
  class="mt-32"
  [options]="quote.options"
  [option]="quote.options.indexOf(option)"
  [caporepartoApprovals]="caporepartoApprovals"
  (optionChanged)="changeOption($event)"
>
</ui-quote-options-tab>

<ui-quote-detail-option
  *ngIf="option"
  class="mt-16"
  [budgetLimit]="budgetLimit"
  [option]="option"
  [overBudget]="quote.overbudget"
  [showGenericOverBudgetWarning]="showGenericOverBudgetWarning"
  [showBudgetLimit]="showBudgetLimit"
  [showOverBudgetAmount]="showOverBudgetAmount"
  (openSliderEvent)="openSlider($event)"
>
</ui-quote-detail-option>
