import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TickService {
  public tick$ = interval(1000).pipe(startWith(0), shareReplay(1));
}
