/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PushNotificationToken } from '../models/push-notification-token';

/**
 * Notifications
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation addNotificationToken
   */
  static readonly AddNotificationTokenPath = '/api/mobile/notification/token/';

  /**
   * Add a new token for push notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNotificationToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNotificationToken$Response(params: {
    context?: HttpContext;
    body: PushNotificationToken;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.AddNotificationTokenPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Add a new token for push notifications.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNotificationToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNotificationToken(params: {
    context?: HttpContext;
    body: PushNotificationToken;
  }): Observable<void> {
    return this.addNotificationToken$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteNotificationToken
   */
  static readonly DeleteNotificationTokenPath =
    '/api/mobile/notification/token/';

  /**
   * Delete a token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteNotificationToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteNotificationToken$Response(params: {
    context?: HttpContext;
    body: PushNotificationToken;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.DeleteNotificationTokenPath,
      'delete'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Delete a token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteNotificationToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteNotificationToken(params: {
    context?: HttpContext;
    body: PushNotificationToken;
  }): Observable<void> {
    return this.deleteNotificationToken$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
