<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="isDraft">
      <ion-button color="primary" [attr.aria-label]="'pages.insert.modal.delete' | translate" (click)="delete()" data-cy="Delete draft">
        {{ 'pages.insert.modal.delete' | translate }}
      </ion-button>
    </ion-buttons>

    <ion-title>{{ 'pages.insert.modal.title' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" [attr.aria-label]="'pages.insert.modal.cancel' | translate" (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ui-current-cdc [costCenter]="costCenter" [showSuperOperatore]="isSuperOperatoreInMacroItem"></ui-current-cdc>
</ion-header>

<ion-content class="ion-padding">
  <front-end-insert-quote-form
    *ngIf="form"
    [id]="'insert-quote-form'"
    [form]="form"
    [assignees]="assignees"
    [costCenter]="costCenter"
    [canInsertBudgetItem]="isResponsabile"
    [budgetMacroItems]="budgetMacroItems"
    (submitted)="send($event)"
    (submittedWithBudgetItem)="sendAndApprove($event)"
    (changedMacroItem)="checkIfSuperOperatoreInMacroItem($event)"
    [showMacroItems]="isAnySuperOperatore"
    #quoteForm
  ></front-end-insert-quote-form>
</ion-content>

<ion-footer class="ion-no-border" mode="ios">
  <ion-toolbar>
    <div class="flex flex-column gap-8">
      <ion-button type="submit" form="insert-quote-form" color="primary" data-cy="Send quote">
        {{
          (costCenter.role === RoleEnum.Responsabile ? 'pages.insert.modal.confirm' : 'pages.insert.modal.confirmForApproval') | translate
        }}
      </ion-button>

      <div class="flex flex-row align-items-center justify-content-center">
        <ion-label class="fs-13">{{ 'pages.insert.modal.notReady' | translate }}</ion-label>
        <ion-button (click)="draft(quoteFormComponent.formValue())" fill="clear" size="small" data-cy="Draft quote">
          {{ 'pages.insert.modal.draft' | translate }}
        </ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
