import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BudgetMacroItem } from '@libs/core/api';
import { CheckboxListOption } from '../checkbox-list/abstract-checkbox-list.component';

interface MacroItemForm {
  macroItem: FormControl<(BudgetMacroItem | undefined)[]>;
}

function buildMacroItemForm(
  macroItem?: BudgetMacroItem
): FormGroup<MacroItemForm> {
  return new FormBuilder().group<MacroItemForm>({
    macroItem: new FormControl(macroItem ? [macroItem] : [], {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });
}

@Component({
  selector: 'ui-select-macroitem-modal',
  templateUrl: './select-macroitem-modal.component.html',
  styleUrls: ['./select-macroitem-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMacroItemModalComponent {
  @Input() set macroItems(macroItems: BudgetMacroItem[]) {
    this.options = [
      {
        label: '',
        options: macroItems.map((macroItem) => ({
          label: `${macroItem.name}`,
          value: macroItem,
        })),
      },
    ];
  }
  @Input() macroItem!: BudgetMacroItem;
  options: CheckboxListOption[] = [];

  readonly form = buildMacroItemForm(this.macroItem);

  constructor(private modalController: ModalController) {}

  back() {
    this.modalController.dismiss();
  }

  apply(form: FormGroup<MacroItemForm>) {
    if (form.valid) {
      this.modalController.dismiss(
        form.value.macroItem && form.value.macroItem[0]
      );
    }
  }
}
