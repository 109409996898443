import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  FileService,
  FileSystemService,
  SendIntentService,
} from '@front-end/core/services';
import { minLengthArray } from '@front-end/core/utils';
import { QuoteOption } from '@libs/core/api';
import { Observable, debounceTime, finalize, from, mergeMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Intent } from 'send-intent';

export interface QuoteOptionForm {
  attachments: FormArray<FormControl<string>>;
  price: FormControl<number | undefined>;
  notes: FormControl<string | null>;
  noteId: FormControl<number | null | undefined>;
  id: FormControl<number | undefined>;
}

export function buildQuoteOptionForm(
  option?: QuoteOption
): FormGroup<QuoteOptionForm> {
  return new FormBuilder().group<QuoteOptionForm>({
    attachments: new FormArray<FormControl<string>>(
      option?.attachments?.map(
        (attachment) =>
          new FormControl<string>(attachment, { nonNullable: true })
      ) ?? [],
      {
        validators: [minLengthArray(1)],
      }
    ),
    price: new FormControl<number | undefined>(
      option?.price != null ? option.price / 100 : undefined,
      {
        nonNullable: true,
        validators: [Validators.required, Validators.min(0)],
      }
    ),
    //qui sara' array?
    notes: new FormControl<string | null>(option?.notes?.[0]?.note ?? null),
    noteId: new FormControl<number | undefined>(
      option?.notes?.[0]?.id ?? undefined
    ),
    id: new FormControl<number | undefined>(option?.id ?? undefined, {
      nonNullable: true,
    }),
  });
}

@UntilDestroy()
@Component({
  selector: 'front-end-insert-quote-form-option',
  templateUrl: './insert-quote-form-option.component.html',
  styleUrls: ['./insert-quote-form-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertQuoteFormOptionComponent implements OnInit {
  @Input() form!: FormGroup<QuoteOptionForm>;
  @ViewChild('placeholder', { read: ElementRef }) placeholder!: ElementRef;
  @Output() openPreviewEvent = new EventEmitter();

  constructor(
    private changeRef: ChangeDetectorRef,
    private fileService: FileService,
    private sendIntentService: SendIntentService,
    private fileSystemService: FileSystemService
  ) {}

  ngOnInit(): void {
    this.form.controls.attachments.valueChanges
      .pipe(untilDestroyed(this), debounceTime(0))
      .subscribe(() => {
        this.placeholder.nativeElement.scrollIntoView({
          behavior: 'smooth',
        });
      });

    if (this.sendIntentService.hasIntent()) {
      from(this.sendIntentService.getIntents())
        .pipe(
          mergeMap((intent) => this.mergeMapAttachment(intent)),
          finalize(() => this.sendIntentService.clearIntent())
        )
        .subscribe((attachment) => {
          this.addAttachment(attachment);
          this.changeRef.markForCheck();
        });
    }
  }

  addAttachment(attachment: string): void {
    this.form.controls.attachments.push(
      new FormControl<string>(attachment, { nonNullable: true })
    );
  }

  onChangeAttachment(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.fileService.read(file).subscribe((attachment) => {
        this.addAttachment(attachment);
        this.changeRef.markForCheck();
      });
    }
  }

  removeAttachment(index: number): void {
    this.form.controls.attachments.removeAt(index);
  }

  mergeMapAttachment(intent: Intent): Observable<string> {
    return this.fileSystemService.readFile({ path: intent.url as string }).pipe(
      mergeMap(({ data }) =>
        this.fileService.write(
          data as string,
          intent.title as string,
          intent.type as string
        )
      ),
      mergeMap((file) => this.fileService.read(file))
    );
  }

  openSlider(attachment: string) {
    this.openPreviewEvent.emit(attachment);
  }
}
