import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import {
  CostCenterSummary,
  Identity,
  Quote,
  QuoteService,
  RoleEnum,
} from '@libs/core/api';

import { EventManagerService } from '@front-end/core/services';
import { from, tap } from 'rxjs';
import { LoadingService, ToastService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ui-quote-detail-modal',
  templateUrl: './quote-detail-modal.component.html',
  styleUrls: ['./quote-detail-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteDetailModalComponent {
  @Input() costCenter!: CostCenterSummary;
  @Input() quote!: Quote;
  @Input() identity!: Identity;
  @Input() showOverBudgetAmount = false;

  constructor(
    private modalController: ModalController,
    private quoteService: QuoteService,
    private eventManager: EventManagerService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private alertController: AlertController,
    private translateService: TranslateService
  ) {}

  readonly RoleEnum = RoleEnum;

  cancel() {
    this.modalController.dismiss();
  }

  delete() {
    this.deleteWarning().subscribe(() => {
      this.quoteService
        .quoteDelete({ quoteId: Number(this.quote.id) })
        .pipe(
          this.toastService.catchAndThrowErrorWithToast(
            'messages.errors.deleteQuote'
          ),
          this.loadingService.withLoading(),
          tap(() => this.toastService.success('messages.success.deleteQuote'))
        )
        .subscribe(() => {
          this.eventManager.notifyStateChange('quote-deleted');
          this.modalController.dismiss();
        });
    });
  }

  private deleteWarning() {
    return from(
      this.alertController.create({
        htmlAttributes: { 'data-cy': 'Delete confirmation' },
        mode: 'ios',
        header: this.translateService.instant('messages.confirm.warning'),
        subHeader: this.translateService.instant(
          'messages.confirm.deleteQuote'
        ),
        buttons: [
          {
            text: this.translateService.instant('messages.confirm.cancel'),
            role: 'cancel',
          },
          {
            text: this.translateService.instant(
              'messages.confirm.confirmDeletion'
            ),
            role: 'confirm',
          },
        ],
      })
    ).pipe(
      tap((alert) => alert.present()),
      switchMap((alert) => alert.onDidDismiss()),
      filter((event) => event.role === 'confirm')
    );
  }
}
