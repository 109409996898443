import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'front-end-insert-quote-sent-modal',
  templateUrl: './insert-quote-sent-modal.component.html',
  styleUrls: ['./insert-quote-sent-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertQuoteSentModalComponent {
  constructor(private modalController: ModalController) {}

  close() {
    this.modalController.dismiss();
  }
}
