import { DBConfig } from 'ngx-indexed-db';

export function migrationFactory() {
  return {
    // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-explicit-any
    1: (db: any, transaction: any) => {
      const store = transaction.objectStore('uploaded-files');
      store.createIndex('uuids', 'uuid', { unique: true });
      store.createIndex('timestamps', 'timestamp');
    },
  };
}

export const dbConfig: DBConfig = {
  name: 'StpDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'uploaded-files',
      storeConfig: { keyPath: 'uuid', autoIncrement: false },
      storeSchema: [
        { name: 'uuid', keypath: 'uuid', options: { unique: true } },
        { name: 'data', keypath: 'data', options: { unique: false } },
        { name: 'timestamp', keypath: 'timestamp', options: { unique: false } },
      ],
    },
  ],
  migrationFactory,
};
