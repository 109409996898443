import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AbstractCheckboxListComponent,
  CheckboxListOption,
} from '../abstract-checkbox-list.component';

@Component({
  selector: 'ui-accordion-checkbox-list',
  templateUrl: './accordion-checkbox-list.component.html',
  styleUrls: ['./accordion-checkbox-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AccordionCheckboxListComponent,
    },
  ],
})
export class AccordionCheckboxListComponent extends AbstractCheckboxListComponent {
  @Input() set options(value: CheckboxListOption[]) {
    this._listOptions = value;
    this._allOptions = value.flatMap((listOptions) => listOptions.options);
    this._incrementalLengthsOfOptions = this.computeIncrementalOptionsLengths(
      this._listOptions
    );

    this.resetForm(this._allOptions);
  }

  @Input() defaultAllValue: unknown = undefined;

  @Input() multi = false;
}
