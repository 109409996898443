import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private http: HttpClient, handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  download(url: string, fileName?: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe((blob) => {
      const objectURL = URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      if (fileName) {
        anchor.download = fileName;
      } else {
        anchor.download = url.split('/').pop() || '';
      }

      anchor.click();
      URL.revokeObjectURL(objectURL);
    });
  }

  downloadAsBlob(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }
}
