<front-end-insert-confirm-screen
  icon="file-tray-outline"
  [title]="'pages.insert.draft.title' | translate"
  [subtitle]="'pages.insert.draft.subtitle' | translate"
  [action]="'pages.insert.draft.action' | translate"
  data-cy="Drafted quote"
  [actionId]="'See drafted quotes'"
  (actionClick)="close()"
>
</front-end-insert-confirm-screen>
