import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials',
})
export class UserInitialsPipe implements PipeTransform {
  transform(value?: { fullName?: string; name?: string } | null): unknown {
    const name = value?.name || value?.fullName;
    if (name) {
      return name[0].toUpperCase();
    }

    return '--';
  }
}
