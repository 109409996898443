import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-user-profile-image',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileImageComponent {
  @Input() user?: { fullname?: string; name?: string };
  @Input() size = 55;
  @Input() fontSize = 31;
}
