import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CompanySummary, CostCenterSummary } from '@libs/core/api';

@Component({
  selector: 'ui-cost-centers-list',
  templateUrl: './cost-centers-list.component.html',
  styleUrls: ['./cost-centers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostCentersListComponent {
  @Input() companies?: CompanySummary[];
  @Output() selected = new EventEmitter<CostCenterSummary>();
}
