/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UploadedFile } from '../models/uploaded-file';

/**
 * Uploaded files
 */
@Injectable({
  providedIn: 'root',
})
export class FilesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getUploadedFile
   */
  static readonly GetUploadedFilePath = '/api/uploaded-files/{uuid}';

  /**
   * Get uploaded file by UUID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUploadedFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUploadedFile$Response(params: {
    /**
     * The uuid of the uploaded file
     */
    uuid: string;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<UploadedFile>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FilesService.GetUploadedFilePath,
      'get'
    );
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UploadedFile>;
        })
      );
  }

  /**
   * Get uploaded file by UUID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUploadedFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUploadedFile(params: {
    /**
     * The uuid of the uploaded file
     */
    uuid: string;
    context?: HttpContext;
  }): Observable<UploadedFile> {
    return this.getUploadedFile$Response(params).pipe(
      map((r: StrictHttpResponse<UploadedFile>) => r.body as UploadedFile)
    );
  }
}
