<ion-header class="ion-no-border" mode="ios">
  <ion-toolbar color="light">
    <ion-title class="fs-18 font-medium" color="dark" data-cy="Macroitem selection">
      {{ 'pages.insert.selectMacroItem.title' | translate }}
    </ion-title>

    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form id="select-macroitem-form" [formGroup]="form" (ngSubmit)="apply(form)">
    <ui-simple-checkbox-list data-cy="Macroitem select" class="mb-16" [options]="options" [formControl]="form.controls.macroItem">
      <ng-template let-option>
        <div class="flex flex-column column-gap-8 py-8">
          <div class="fs-16 font-medium">{{ option.value.name }}</div>
          <div
            class="bg-primary-ultra-light text-primary px-8 py-4 border-radius-4 font-medium fs-15 text-uppercase flex-place-start"
            *ngIf="option.value.superOperatore"
          >
            {{ 'pages.insert.selectMacroItem.superOperatore' | translate }}
          </div>
        </div>
      </ng-template>
    </ui-simple-checkbox-list>
  </form>
</ion-content>

<ion-footer class="ion-no-border" mode="ios">
  <ion-toolbar color="light">
    <ion-button class="w-full m-0" form="select-macroitem-form" type="submit" color="primary" data-cy="Apply selection">
      {{ 'pages.insert.selectMacroItem.apply' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
