import { Injectable } from '@angular/core';
import {
  Filesystem,
  ReadFileOptions,
  ReadFileResult,
} from '@capacitor/filesystem';
import { Observable, filter, from, of, switchMap } from 'rxjs';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class FileSystemService {
  private readonly enabled = Capacitor.isNativePlatform();

  readFile(options: ReadFileOptions): Observable<ReadFileResult> {
    return of(this.enabled).pipe(
      filter((enabled) => enabled),
      switchMap(() => from(Filesystem.readFile(options)))
    );
  }
}
