import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function updateFormTreeValueAndValidity(
  abstractControl: AbstractControl
): void {
  abstractControl.markAsDirty();
  abstractControl.updateValueAndValidity();

  if (
    abstractControl instanceof FormGroup ||
    abstractControl instanceof FormArray
  ) {
    Object.keys(abstractControl.controls).forEach((key: string) => {
      const descendant = abstractControl.get(key);
      if (descendant) {
        updateFormTreeValueAndValidity(descendant);
      }
    });
  }
}
