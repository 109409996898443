import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  static readonly INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';

  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  public error(title = ToastService.INTERNAL_SERVER_ERROR): void {
    this.openToast(title, 'danger');
  }

  public warn(title: string): void {
    this.openToast(title, 'warning');
  }

  public success(
    title = 'notifications.genericSuccess.title',
    params?: { [key: string]: unknown }
  ): void {
    this.openToast(title, 'success', params);
  }

  public catchAndThrowErrorWithToast<T>(
    title?: string
  ): (source: Observable<T>) => Observable<T> {
    return catchError((error) => {
      this.error(this.getLabelKey(title ?? error));
      return throwError(() => error);
    });
  }

  public catchErrorWithToast<T>(): (source: Observable<T>) => Observable<T> {
    return catchError((error: HttpErrorResponse) => {
      this.error(this.getLabelKey(error));
      return EMPTY;
    });
  }

  public withSuccess<T>(
    title?: string
  ): (source: Observable<T>) => Observable<T> {
    return tap(() => {
      this.success(title ?? 'notifications.genericSuccess.title');
    });
  }

  private getLabelKey(error: string | HttpErrorResponse): string {
    if (typeof error === 'string') {
      return error;
    }

    if (error.status === 500) {
      return ToastService.INTERNAL_SERVER_ERROR;
    } else {
      return (
        error?.error?.message ??
        error?.message ??
        'messages.errors.genericServiceError'
      );
    }
  }

  private openToast(
    title: string,
    severity: string,
    titleParams?: { [key: string]: unknown }
  ): void {
    from(
      this.toastController.create({
        header: this.translateService.instant(title, titleParams),
        position: 'top',
        duration: 2000,
        color: severity,
      })
    ).subscribe((toast) => toast.present());
  }
}
