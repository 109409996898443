import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SegmentChangeEventDetail } from '@ionic/angular';
import { CaporepartoApprovals } from '../caporeparto-quote-detail-modal/caporeparto-quote-detail-modal.component';

export type SegmentEvent = Event & Record<'detail', SegmentChangeEventDetail>;

@Component({
  selector: 'ui-quote-options-tab',
  templateUrl: './quote-options-tab.component.html',
  styleUrls: ['./quote-options-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteOptionsTabComponent<T> {
  @Input() options!: T[];
  @Input() option!: number;
  @Input() caporepartoApprovals?: CaporepartoApprovals[];

  @Output() optionChanged: EventEmitter<SegmentEvent> = new EventEmitter();

  change(event: Event) {
    this.optionChanged.emit(event as SegmentEvent);
  }
}
