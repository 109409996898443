import { SplashScreen } from '@capacitor/splash-screen';

export const initApp = () => async () => {
  await SplashScreen.hide();

  /**
   * Show the splashscreen. It's not the native splashscreen, but a simple
   * native page with the same image shown in the native splashscreen.
   */
  await SplashScreen.show({
    autoHide: false,
  });
};
