import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'ui-approve-reject-toggle-buttons',
  templateUrl: './approve-reject-toggle-buttons.component.html',
  styleUrls: ['./approve-reject-toggle-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApproveRejectToggleButtonsComponent {
  @Input() forceColour = false;
  @Input() approved?: boolean;
  @Input() rejectText = '';
  @Input() approveText = '';
  @Output() approvalResult = new EventEmitter<boolean>();

  reject() {
    this.approvalResult.emit(false);
  }

  approve() {
    this.approvalResult.emit(true);
  }
}
