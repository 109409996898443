import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BudgetMacroItem } from '@libs/core/api';
import { from, switchMap, tap } from 'rxjs';
import { SelectMacroItemModalComponent } from '../select-macroitem-modal/select-macroitem-modal.component';

@Component({
  selector: 'ui-select-macroitem',
  templateUrl: './select-macroitem.component.html',
  styleUrls: ['./select-macroitem.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectMacroItemComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMacroItemComponent implements ControlValueAccessor {
  @Input() macroItems!: BudgetMacroItem[];

  value: BudgetMacroItem | undefined;

  onChange!: (value: BudgetMacroItem | undefined) => void;
  onTouched!: (value: BudgetMacroItem | undefined) => void;

  constructor(
    private changeRef: ChangeDetectorRef,
    private modalController: ModalController
  ) {}

  writeValue(value: BudgetMacroItem | undefined): void {
    this.value = value;
    this.changeRef.detectChanges();
  }

  registerOnChange(fn: (value: BudgetMacroItem | undefined) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: BudgetMacroItem | undefined) => void): void {
    this.onTouched = fn;
  }

  openMacroItemSelection() {
    from(
      this.modalController.create({
        component: SelectMacroItemModalComponent,
        mode: 'md',
        componentProps: {
          macroItems: this.macroItems,
          macroItem: this.value,
        },
      })
    )
      .pipe(
        tap((modal) => modal.present()),
        switchMap((modal) => from(modal.onDidDismiss<BudgetMacroItem>()))
      )
      .subscribe((event) => {
        if (event.data) {
          this.writeValue(event.data);
          this.onChange(event.data);
        }
      });
  }
}
