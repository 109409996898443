import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, isPlatform } from '@ionic/angular';

import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DefaultErrorHandler } from '@front-end/core/utils';
import {
  ApiModule,
  AuthService,
  BackendAuthenticationProvider,
} from '@libs/core/api';
import { UikitModule } from '@libs/ui/uikit';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
  AuthenticationProvider,
  LocalStorageProvider,
  NgxAuthUtilsModule,
  StorageProvider,
} from 'ngx-auth-utils';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initApp } from './app.initializer';
import { dbConfig } from './indexeddb.config';

registerLocaleData(localeIt);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
declare global {
  interface Window {
    pdfWorkerSrc: string;
  }
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    PdfViewerModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      backButtonText: '',
      scrollAssist: !isPlatform('mobileweb'),
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'it',
    }),
    ApiModule.forRoot({
      rootUrl: environment.apiUrl,
    }),
    NgxAuthUtilsModule.forRoot({
      authenticationProvider: {
        provide: AuthenticationProvider,
        useClass: BackendAuthenticationProvider,
        deps: [AuthService],
      },
      storageProvider: {
        provide: StorageProvider,
        useClass: LocalStorageProvider,
      },
      homeUrl: '/home',
      noAuthRedirectUrl: '/user/login',
      sessionExpiredRedirectUrl: '/user/login',
      unauthorizedUrlBlacklist: [AuthService.LoginPath],
      refreshToken: true,
      storageKeyPrefix: 'teleconsulting-stp',
      tokenType: 'Bearer',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxIndexedDBModule.forRoot(dbConfig),
    UikitModule,
  ],

  providers: [
    {
      provide: ErrorHandler,
      useValue: new DefaultErrorHandler(),
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

//window.pdfWorkerSrc = 'assets/libs/pdf.worker.min.js';
