import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

export type ErrorsType = { [key: string]: string } | null;

export type ErrorType = { key: string; value: string; standard: boolean };

@Component({
  selector: 'ui-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements OnInit {
  private readonly standardErrors = [
    'required',
    'email',
    'maxlength',
    'minlength',
    'blank',
    'min',
    'max',
  ];

  firstError$?: Observable<ErrorType | undefined>;
  dirtyOrTouched$?: Observable<boolean>;
  required$?: Observable<boolean>;

  @Input() control!: AbstractControl;
  @Input() label = 'not-optional';
  @Input() labelPosition? = 'floating';
  @Input() showLabel = true;
  @Input() icon?: string;
  @Input() iconSlot = 'start';
  @Input() iconClasses = 'input-label-icon';
  @Input() class = '';
  @Input() lines = '';

  ngOnInit(): void {
    this.dirtyOrTouched$ = this.control.statusChanges.pipe(
      startWith(this.control.status),
      map(() => this.control.dirty || this.control.touched)
    );
    this.firstError$ = this.control.statusChanges.pipe(
      startWith(this.control.status),
      debounceTime(400),
      map(() => this.updateErrors(this.control.errors)),
      map((errors) => (errors.length > 0 ? errors[0] : undefined))
    );
    this.required$ = this.control.statusChanges.pipe(
      startWith(this.control.status),
      map(
        () =>
          this.control.hasValidator(Validators.required) && this.control.enabled
      )
    );
  }

  private updateErrors(errors?: ErrorsType): ErrorType[] {
    if (!errors) {
      return [];
    }

    return Object.keys(errors).map((key) => ({
      key: key,
      value: errors[key],
      standard: this.standardErrors.includes(key),
    }));
  }
}
