import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FileData } from '@front-end/core/services';

import { Platform } from '@ionic/angular';

import { getFileType, saveAndOpenPdfReport } from '@front-end/core/utils';
import { DownloadService } from '../../services/download.service';

@Component({
  selector: 'ui-slider-item',
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush, Disabled because the pdf viewer is not working correctly
})
export class SliderItemComponent {
  file?: FileData;
  rotation = this.isAndroidApp() ? 90 : 0;

  @Input() set fileUrl(value: string) {
    this.file = {
      type: getFileType(value),
      data: value,
      downloadable: true,
    } as FileData;
  }

  constructor(
    private downloadService: DownloadService,
    public platform: Platform,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  download(data: string) {
    this.downloadService.download(data);
  }

  onLoadComplete() {
    if (this.isAndroidApp()) {
      this.workaroundForPDFRendering();
    }
  }

  private workaroundForPDFRendering() {
    setTimeout(() => {
      this.rotation = 0;
      this.changeDetectorRef.detectChanges();
    }, 200);
  }

  async downloadPDF(file: FileData) {
    if (this.platform.is('capacitor')) {
      this.downloadService.downloadAsBlob(file.data).subscribe((blob) => {
        saveAndOpenPdfReport(blob);
      });
    } else {
      this.download(file.data);
    }
  }

  private isAndroidApp() {
    return this.platform.is('android') && this.platform.is('capacitor');
  }
}
