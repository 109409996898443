import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { CostCentersService } from '@front-end/core/services';
import { CostCenterSummary, RoleEnum } from '@libs/core/api';
import { catchError, map, Observable, of } from 'rxjs';
import { ToastService } from '../services';

interface AuthorizationGuardData {
  roles: RoleEnum[];
  redirectTo: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard
  implements CanActivate, CanActivateChild, CanLoad
{
  private costCenter$ = this.costCentersService.selectedCostCenter$;

  constructor(
    private router: Router,
    private costCentersService: CostCentersService,
    private toastService: ToastService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.can(route);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> {
    return this.can(route);
  }

  canLoad(route: Route): Observable<boolean | UrlTree> {
    return this.can(route);
  }

  private can(
    route: ActivatedRouteSnapshot | Route
  ): Observable<boolean | UrlTree> {
    const data = this.getData(route);

    return this.costCenter$.pipe(
      map((costCenter) => {
        const isAuthorized = this.isAuthorized(costCenter, data.roles);

        return isAuthorized ? true : this.router.parseUrl(data.redirectTo);
      }),
      catchError(() => {
        this.toastService.warn('messages.errors.costCenterNotFound');
        return of(this.router.parseUrl('/user/cost-centers'));
      })
    );
  }

  private isAuthorized(
    costCenter: CostCenterSummary,
    roles: RoleEnum[]
  ): boolean {
    return roles && roles.includes(costCenter.role as RoleEnum);
  }

  private getData(
    route: ActivatedRouteSnapshot | Route
  ): AuthorizationGuardData {
    return route.data as AuthorizationGuardData;
  }
}
