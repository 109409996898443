import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'statusHistory',
})
export class StatusHistoryPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(status?: string): string {
    if (status) {
      return this.translateService.instant(`quote.statusHistory.${status}`);
    }

    return '';
  }
}
