import { ɵComponentType as ComponentType } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

// This should not thought in school. I mean, the whole file.

function decorateViewWillEnter<T>(
  ionViewWillEnter: (() => void) | null | undefined
) {
  return function (this: ComponentType<T>) {
    // Invoke the original `ionViewWillEnter` if it exists
    ionViewWillEnter && ionViewWillEnter.call(this);

    TabsService.hideTabs();
  };
}

function decorateViewWillLeave<T>(
  ionViewWillLeave: (() => void) | null | undefined
) {
  return function (this: ComponentType<T>) {
    // Invoke the original `ionViewWillEnter` if it exists
    ionViewWillLeave && ionViewWillLeave.call(this);

    TabsService.showTabs();
  };
}

export function NoTabsPage<T>(): ClassDecorator {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return (type: any | ComponentType<T>) => {
    type.prototype.ionViewWillEnter = decorateViewWillEnter(
      type.prototype.ionViewWillEnter
    );
    type.prototype.ionViewWillLeave = decorateViewWillLeave(
      type.prototype.ionViewWillLeave
    );
  };
}

export class TabsService {
  private static _showTabs$ = new BehaviorSubject(true);

  public static get showTabs$() {
    return TabsService._showTabs$.asObservable().pipe(distinctUntilChanged());
  }

  public static showTabs() {
    TabsService._showTabs$.next(true);
  }

  public static hideTabs() {
    TabsService._showTabs$.next(false);
  }
}
