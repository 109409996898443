import { Pipe, PipeTransform } from '@angular/core';
import { RoleEnum } from '@libs/core/api';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'role',
})
export class RolePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(
    role?: RoleEnum | null,
    short = true,
    superOperatore?: boolean | null
  ): string {
    if (role) {
      if (role === RoleEnum.Operatore && superOperatore) {
        return this.translateService.instant(
          `roles.SUPEROPERATORE.${short ? 'short' : 'long'}`
        );
      }
      return this.translateService.instant(
        `roles.${role}.${short ? 'short' : 'long'}`
      );
    }

    return '';
  }
}
