<ion-header mode="ios">
  <ion-toolbar color="secondary">
    <ion-title class="text-grey-ultra-dark">{{ 'pages.quotes.detail.title' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button [attr.aria-label]="'pages.quotes.modal.cancel' | translate" (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ui-current-cdc [costCenter]="(costCenter$ | async) ?? undefined"></ui-current-cdc>
</ion-header>

<ion-content class="ion-padding">
  <p class="fs-16 text-grey-ultra-dark font-medium" *ngFor="let history of quoteHistory$ | async">
    {{ history.changeDateTime | date: 'dd/MM/yy' }}: {{ history.newStatus | statusHistory }} ({{ history.user.name }}) -
    {{ history.user.role | role: false }}
  </p>
</ion-content>
