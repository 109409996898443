<ion-list data-cy="Cost centers">
  <ion-item-group *ngFor="let company of companies">
    <ion-item-divider>
      <ion-label>
        {{ company.name }}
      </ion-label>
    </ion-item-divider>

    <ion-item class="reduced" *ngFor="let costCenter of company.costCenters" [button]="true" (click)="selected.emit(costCenter)">
      <span class="w-full ion-text-left">{{ costCenter.description }}</span>
      <ion-icon size="small" slot="end" name="chevron-forward-outline"></ion-icon>
    </ion-item>
  </ion-item-group>
</ion-list>
