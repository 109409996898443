<ion-header mode="ios">
  <ion-toolbar color="secondary">
    <ion-title class="text-grey-ultra-dark" data-cy="In approval quote details">{{ 'pages.quotes.detail.title' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button [attr.aria-label]="'pages.quotes.modal.cancel' | translate" (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ui-current-cdc [costCenter]="costCenter"></ui-current-cdc>
</ion-header>

<ion-content class="ion-padding">
  <ui-quote-detail
    #quoteDetails
    [identity]="identity"
    [showStatus]="false"
    [showMacroBudgetItem]="true"
    [budgetLimit]="budgetLimit"
    [showGenericOverBudgetWarning]="true"
    [showOverBudgetAmount]="true"
    [detail]="_quote"
  >
    <div macroItem [ngClass]="form.controls.budgetItem.value ? '-mb-20' : 'mb-48'">
      <ui-form-field
        [control]="form.controls.budgetItem"
        icon="chevron-forward"
        iconSlot="end"
        iconClasses="icon-end fs-16 pt-32"
        class="fs-16"
        lines="full"
        label="pages.approvals.details.budgetItem"
      >
        <ui-budget-item-select
          [formControl]="form.controls.budgetItem"
          [costCenter]="costCenter"
          [macroBudgetItems]="_quote.assignee?.budgetMacroItems ?? []"
        ></ui-budget-item-select>
      </ui-form-field>
      <ui-budget-amounts
        class="mt-8 mb-28"
        *ngIf="form.controls.budgetItem.value"
        [budgetItem]="form.controls.budgetItem.value"
      ></ui-budget-amounts>
    </div>
  </ui-quote-detail>
  <form *appVar="this.commentForm?.controls?.options?.at(quoteDetails.optionNumber - 1) as option" [formGroup]="option">
    <ui-form-field [control]="option.controls.comment" class="fs-16" lines="full" label="pages.approvals.comment">
      <ion-input type="text" [formControl]="option.controls.comment" data-cy="Comment"></ion-input>
    </ui-form-field>
  </form>
</ion-content>

<ion-footer class="ion-no-border external-page-footer" mode="ios">
  <ion-toolbar>
    <ion-buttons>
      <ion-button color="primary" expand="block" fill="solid" data-cy="Confirm option" (click)="approve(quoteDetails.option)">{{
        'pages.approvals.approveOption' | translate: { option: 'OPT ' + quoteDetails.optionNumber }
      }}</ion-button>
      <ion-button
        class="fs-15"
        type="button"
        color="primary"
        expand="block"
        fill="clear"
        data-cy="Reject all options"
        (click)="rejectAll()"
      >
        <span class="text-grey-ultra-dark">{{ 'pages.approvals.rejectAll.part1' | translate }}&nbsp;</span>
        {{ 'pages.approvals.rejectAll.part2' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
