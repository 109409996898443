import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CostCenterSummary, Identity, Quote } from '@libs/core/api';

@Component({
  selector: 'ui-supervisore-quote-detail-modal',
  templateUrl: './supervisore-handled-quote-detail-modal.component.html',
  styleUrls: ['./supervisore-handled-quote-detail-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupervisoreHandledQuoteDetailModalComponent {
  @Input() costCenter!: CostCenterSummary;

  @Input() set quote(quote: Quote) {
    this._quote = quote;
  }

  _quote!: Quote;
  @Input() identity!: Identity;
  @Input() budgetLimit?: number;

  constructor(private modalController: ModalController) {}

  cancel() {
    this.modalController.dismiss(undefined, 'cancel');
  }
}
