import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export class AppValidators {
  static matchPassword(
    firstInputName: string,
    secondInputName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get(firstInputName)?.value;
      const confirm = control.get(secondInputName)?.value;

      if (password != confirm) {
        const error = { noMatch: true };
        control.get(secondInputName)?.setErrors(error);
        return error;
      }

      return null;
    };
  }

  static requireFieldsIfOtherFieldHasValue(
    fieldToValidateInputName: string,
    otherFieldInputName: string,
    requiredValue: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fieldToValidate = control.get(fieldToValidateInputName)?.value;
      const otherField = control.get(otherFieldInputName)?.value;

      control.get(fieldToValidateInputName)?.clearValidators();

      if (otherField === requiredValue && !fieldToValidate) {
        control
          .get(fieldToValidateInputName)
          ?.addValidators(Validators.required);
        return null;
      }

      return null;
    };
  }

  static requireFieldsIfOtherFieldHasNotValue(
    fieldToValidateInputName: string,
    otherFieldInputName: string,
    requiredValue: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fieldToValidate = control.get(fieldToValidateInputName)?.value;
      const otherField = control.get(otherFieldInputName)?.value;

      control.get(fieldToValidateInputName)?.clearValidators();

      if (otherField !== requiredValue && !fieldToValidate) {
        control
          .get(fieldToValidateInputName)
          ?.addValidators(Validators.required);
        return null;
      }

      return null;
    };
  }

  static notBlank(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const text = control.value?.toString() ?? '';
      if (text.length === 0) return null;

      return text.trim() == '' ? { blank: true } : null;
    };
  }

  static email(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const emailError = Validators.email(control);
      if (emailError === null) {
        const patternValidatorFn = Validators.pattern(/.*@.*\..*/);
        const patternError = patternValidatorFn(control);
        if (patternError) {
          return { email: true };
        }
        return null;
      }
      return emailError;
    };
  }

  static maxDate(maxDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value > maxDate) {
        return { maxDate };
      }

      return null;
    };
  }

  static minDate(minDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value < minDate) {
        return { minDate };
      }

      return null;
    };
  }

  static atLeastOne(...controlNames: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controls = controlNames.map((name) => control.get(name));
      if (controls) {
        const emptyControls = controls.map((c) => c?.value).filter((v) => !v);
        if (emptyControls.length == controlNames.length) {
          return { atLeastOne: true };
        }

        return null;
      }

      return null;
    };
  }

  static positiveNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const message = { nonPositive: { value: control.value } };
      if (control.value == null) return message;
      if (Number(control.value) < 0) return message;
      return null;
    };
  }

  static passwordValidationWithLowercaseCharUppercaseCharNumberAndSymbol(
    minLength = 8
  ): ValidatorFn {
    return Validators.pattern(
      `^(?=.{${minLength},}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\\W).*$`
    );
  }
}
