import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  BudgetItemSummary,
  BudgetMacroItem,
  BudgetMacroItemWithSo,
  CostCenterSummary,
  Quote,
  RoleEnum,
  UserSummaryMacroItems,
} from '@libs/core/api';

import {
  buildQuoteOptionForm,
  QuoteOptionForm,
} from '../insert-quote-form-option/insert-quote-form-option.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController } from '@ionic/angular';
import {
  getValueOrError,
  updateFormTreeValueAndValidity,
} from '@front-end/core/utils';
import { ImageSliderModalComponent, SegmentEvent } from '@libs/ui/uikit';
import { from, tap } from 'rxjs';
import { NgxAuthService } from 'ngx-auth-utils';

export interface QuoteForm {
  description: FormControl<string>;
  assignee: FormControl<UserSummaryMacroItems | null | undefined>;
  options: FormArray<FormGroup<QuoteOptionForm>>;
  budgetItem: FormControl<BudgetItemSummary | undefined | null>;
  macroItem: FormControl<BudgetMacroItemWithSo | undefined | null>;
}

export function buildQuoteForm(
  quote?: Quote,
  canInsertBudgetItem?: boolean,
  showMacroItems?: boolean,
  firstBudgetMacroItem?: BudgetMacroItem
): FormGroup<QuoteForm> {
  return new FormBuilder().group<QuoteForm>({
    description: new FormControl<string>(quote?.description ?? '', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    assignee: new FormControl<UserSummaryMacroItems | null | undefined>(
      quote?.assignee ?? undefined
    ),
    options: new FormArray<FormGroup<QuoteOptionForm>>(
      quote?.options?.map((option) => buildQuoteOptionForm(option)) ?? [
        buildQuoteOptionForm(),
      ]
    ),
    budgetItem: new FormControl<BudgetItemSummary | undefined>(
      quote?.budgetItem ?? undefined,
      {
        validators: canInsertBudgetItem ? [Validators.required] : [],
      }
    ),
    macroItem: new FormControl<BudgetMacroItemWithSo | undefined>(
      quote?.macroItem ?? firstBudgetMacroItem,
      {
        validators: showMacroItems ? [Validators.required] : null,
      }
    ),
  });
}

const INVALID_QUOTE_MESSAGE = {
  header: 'pages.insert.alert.invalidQuote.title',
  message: 'pages.insert.alert.invalidQuote.description',
  button: 'pages.insert.alert.invalidQuote.confirm',
};

const INVALID_OPTION_MESSAGE = {
  header: 'pages.insert.alert.invalidOption.title',
  message: 'pages.insert.alert.invalidOption.description',
  button: 'pages.insert.alert.invalidOption.confirm',
};

const INVALID_BUDGET_ITEM_MESSAGE = {
  header: 'pages.insert.alert.invalidBudgetItem.title',
  message: 'pages.insert.alert.invalidBudgetItem.description',
  button: 'pages.insert.alert.invalidBudgetItem.confirm',
};

interface Message {
  header: string;
  message: string;
  button: string;
}

@Component({
  selector: 'front-end-insert-quote-form',
  templateUrl: './insert-quote-form.component.html',
  styleUrls: ['./insert-quote-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertQuoteFormComponent {
  @Input() id!: string;
  @Input() form!: FormGroup<QuoteForm>;
  @Input() assignees!: UserSummaryMacroItems[];
  private _costCenter!: CostCenterSummary;
  get costCenter(): CostCenterSummary {
    return this._costCenter;
  }
  @Input() set costCenter(value: CostCenterSummary) {
    this._costCenter = value;
    this.hasMultipleMacroItems =
      !!value.macroItems && value.macroItems.length > 1;
  }

  @Input() canInsertBudgetItem = false;
  @Input() showMacroItems = false;
  @Input() budgetMacroItems: BudgetMacroItem[] = [];

  @Output() submitted: EventEmitter<Partial<Quote>> = new EventEmitter<
    Partial<Quote>
  >();

  @Output() submittedWithBudgetItem: EventEmitter<{
    budgetItem: BudgetItemSummary;
    quote: Partial<Quote>;
  }> = new EventEmitter<{
    budgetItem: BudgetItemSummary;
    quote: Partial<Quote>;
  }>();

  @Output() changedMacroItem: EventEmitter<{ macroItemId: number }> =
    new EventEmitter<{ macroItemId: number }>();

  get option(): FormGroup<QuoteOptionForm> {
    return (
      this.form.controls.options.at(this.selectedOption) ??
      this.form.controls.options.at(0)
    );
  }

  get hasAttachments(): boolean {
    return this.option.controls.attachments.length > 0;
  }

  get hasOptions(): boolean {
    return this.form.controls.options.length > 1;
  }

  get showAssignee(): boolean {
    return (
      this.costCenter.role === RoleEnum.Operatore && this.assignees.length > 1
    );
  }

  hasMultipleMacroItems = false;

  selectedOption = 0;

  constructor(
    private alertController: AlertController,
    private translateService: TranslateService,
    private changeRef: ChangeDetectorRef,
    protected modalController: ModalController,
    private authService: NgxAuthService
  ) {}

  formValue(): Partial<Quote> {
    return {
      ...this.form.value,
      assigneeId: this.form.value.assignee?.id,
      assignee: undefined,
      options: this.form.value.options?.map((option) => ({
        ...option,
        notes: option.notes
          ? [
              {
                note: option.notes,
                user: {
                  userId: this.authService.snapshot.user.id,
                  costCenterId: this.costCenter.id,
                  role: this.costCenter.role,
                },
                id: option.noteId ?? undefined,
              },
            ]
          : [],
        price:
          option.price != null ? Math.round(option.price * 100) : undefined,
        id: option.id != null ? option.id : undefined,
      })),
      macroItemId: this.form.value.macroItem?.id,
      macroItem: undefined,
    } as Partial<Quote>;
  }

  addOption() {
    this.form.controls.options.push(buildQuoteOptionForm());
    this.selectedOption = this.form.controls.options.length - 1;
  }

  removeOption(index: number) {
    this.form.controls.options.removeAt(index);
    this.selectedOption = 5000; // temporary value to force tab to update
    this.changeRef.detectChanges();
    this.changeRef.markForCheck();
    this.selectedOption = 0;
  }

  selectOption(event: SegmentEvent) {
    this.selectedOption = Number(event.detail.value);
  }

  submit() {
    this.check(this.form);

    if (this.form.valid) {
      if (this.canInsertBudgetItem) {
        this.submittedWithBudgetItem.emit({
          budgetItem: getValueOrError(this.form.value.budgetItem),
          quote: this.formValue(),
        });
      } else {
        this.submitted.emit(this.formValue());
      }
    }
  }

  check(form: FormGroup<QuoteForm>) {
    updateFormTreeValueAndValidity(form);

    if (!form.valid) {
      if (form.controls.description.invalid) {
        this.alert(INVALID_QUOTE_MESSAGE);
      } else if (this.canInsertBudgetItem && form.controls.budgetItem.invalid) {
        this.alert(INVALID_BUDGET_ITEM_MESSAGE);
      } else {
        this.alert(INVALID_OPTION_MESSAGE);
      }
    }
  }

  alert(message: Message) {
    from(
      this.alertController.create({
        header: this.translateService.instant(message.header),
        message: this.translateService.instant(message.message),
        buttons: [
          {
            text: this.translateService.instant(message.button),
          },
        ],
      })
    )
      .pipe(tap((alert) => alert.present()))
      .subscribe();
  }

  compareId(o1: { id: unknown }, o2: { id: unknown }) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  openSlider($event: string) {
    from(
      this.modalController.create({
        component: ImageSliderModalComponent,
        mode: 'md',
        componentProps: {
          attachments: this.option.value?.attachments,
          selectedAttachment: $event,
        },
      })
    ).subscribe((modal) => modal.present());
  }
}
