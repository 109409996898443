import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import {
  BudgetItemSummary,
  BudgetMacroItem,
  CostCenterSummary,
  Identity,
  Quote,
  QuoteStatusEnum,
  RoleEnum,
  UserService,
  UserSummaryMacroItems,
} from '@libs/core/api';
import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY,
  filter,
  from,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';

import {
  buildQuoteForm,
  InsertQuoteFormComponent,
  QuoteForm,
} from '../insert-quote-form/insert-quote-form.component';
import { getValueOrError } from '@front-end/core/utils';
import { ApiFiltersService } from '@front-end/core/services';

@Component({
  selector: 'front-end-insert-quote-modal',
  templateUrl: './insert-quote-modal.component.html',
  styleUrls: ['./insert-quote-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertQuoteModalComponent implements OnInit {
  @Input() assignees!: UserSummaryMacroItems[];
  @Input() costCenter!: CostCenterSummary;
  @Input() identity!: Identity;
  @Input() quote!: Quote;
  @Input() budgetLimit?: number | null;

  @ViewChild('quoteForm') quoteFormComponent!: InsertQuoteFormComponent;

  readonly RoleEnum = RoleEnum;
  form!: FormGroup<QuoteForm>;
  canInsertBudgetItem?: boolean;
  isAnySuperOperatore = false;
  budgetMacroItems: BudgetMacroItem[] = [];
  isSuperOperatoreInMacroItem = false;

  ngOnInit() {
    this.isAnySuperOperatore = !!this.identity.companies?.some((c) =>
      c.costCenters
        ?.find((c) => c.id === this.costCenter.id)
        ?.macroItems?.some((m) => m.superOperatore)
    );

    this.budgetMacroItems =
      this.identity.companies?.flatMap(
        (c) => c.costCenters?.flatMap((cc) => cc.macroItems ?? []) ?? []
      ) ?? [];

    const defaultMacroItem =
      this.costCenter.macroItems?.length === 1
        ? this.costCenter.macroItems[0]
        : undefined;
    this.form = buildQuoteForm(
      {
        ...this.quote,
        assignee: this.getAssignee(
          this.assignees,
          this.costCenter,
          this.identity,
          this.quote
        ),
      },
      this.isResponsabile,
      this.isAnySuperOperatore,
      defaultMacroItem
    );
    this.isSuperOperatoreInMacroItem = this.checkIfSuperOperatoreInMacroItem({
      macroItemId: this.quote?.macroItem?.id,
    });
    this.form.get('macroItem')?.valueChanges.subscribe((macroItem) => {
      if (macroItem?.id) {
        this.isSuperOperatoreInMacroItem =
          this.checkIfSuperOperatoreInMacroItem({
            macroItemId: macroItem?.id,
          });
      }
    });

    if (
      this.identity.companies?.every((c) =>
        c.costCenters
          ?.find((c) => c.id === this.costCenter.id)
          ?.macroItems?.every((m) => m.superOperatore)
      )
    ) {
      this.isSuperOperatoreInMacroItem = true;
    }
  }

  get isResponsabile(): boolean {
    return this.costCenter?.role === RoleEnum.Responsabile;
  }

  get isDraft(): boolean {
    return this.quote?.status === QuoteStatusEnum.Draft;
  }

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private translateService: TranslateService,
    private userService: UserService,
    private apiFiltersService: ApiFiltersService
  ) {}

  send(quote: Partial<Quote>) {
    const roleToCheck = this.checkQuoteProspectiveAssignees();

    if (!quote.assignee && !quote.assigneeId) {
      const assignee = this.getAssignee(
        this.assignees,
        this.costCenter,
        this.identity,
        quote as Quote
      );
      quote.assigneeId = assignee?.id;
    }

    this.findQuoteProspectiveAssignees$(roleToCheck).subscribe((value) => {
      if (value) {
        this.modalController.dismiss(
          Object.assign(this.quote ?? {}, quote),
          'draft'
        );
      } else {
        this.modalController.dismiss(
          Object.assign(this.quote ?? {}, quote),
          'send'
        );
      }
    });
  }

  checkQuoteProspectiveAssignees() {
    let roleToCheck;

    switch (this.costCenter?.role) {
      case RoleEnum.Operatore:
        if (this.isSuperOperatoreInMacroItem) {
          roleToCheck = RoleEnum.Responsabile;
        } else {
          roleToCheck = RoleEnum.Caporeparto;
        }
        break;
      case RoleEnum.Caporeparto:
        roleToCheck = RoleEnum.Responsabile;
        break;
      case RoleEnum.Responsabile:
        if (
          this.budgetLimit != null &&
          getValueOrError(getValueOrError(this.form?.value.options)[0].price) *
            100 >
            this.budgetLimit
        ) {
          roleToCheck = RoleEnum.Supervisore;
        }
        break;
      default:
        return null;
    }

    return roleToCheck;
  }

  findQuoteProspectiveAssignees$(roleToCheck?: RoleEnum | null) {
    if (!roleToCheck) {
      return of(null);
    }

    return this.userService
      .costCenterUserList({
        id: getValueOrError(this.costCenter.id),
        $filter: this.apiFiltersService.getUserRoleFilter(roleToCheck),
        $myMacroItems: roleToCheck === RoleEnum.Caporeparto,
      })
      .pipe(
        switchMap((result) =>
          iif(
            () => result.length === 0,
            this.noProspectiveAssigneesDialog$(),
            of(null)
          )
        )
      );
  }

  sendAndApprove($event: {
    quote: Partial<Quote>;
    budgetItem: BudgetItemSummary;
  }) {
    if (this.isResponsabile) {
      const overBudgetAlertDialog$ = this.createOverBudgetAlertDialog$();
      const price = getValueOrError(this.form?.value.options)[0].price;

      iif(
        () =>
          this.budgetLimit != null &&
          getValueOrError(price) * 100 > this.budgetLimit,
        overBudgetAlertDialog$,
        of(EMPTY)
      )
        .pipe(
          mergeMap(() => {
            const role = this.checkQuoteProspectiveAssignees();
            return this.findQuoteProspectiveAssignees$(role);
          })
        )
        .subscribe((value) => {
          if (value) {
            this.modalController.dismiss(
              {
                quote: Object.assign(this.quote ?? {}, $event.quote),
                budgetItem: $event.budgetItem,
              },
              'draft'
            );
          } else {
            this.modalController.dismiss(
              {
                quote: Object.assign(this.quote ?? {}, $event.quote),
                budgetItem: $event.budgetItem,
              },
              'sendAndApprove'
            );
          }
        });
    }
  }

  draft(quote: Partial<Quote>) {
    if (quote.budgetItem) {
      this.modalController.dismiss(
        {
          quote: Object.assign(this.quote ?? {}, quote),
          budgetItem: quote.budgetItem,
        },
        'draftWithBudgetItem'
      );
    }

    this.modalController.dismiss(
      Object.assign(this.quote ?? {}, quote),
      'draft'
    );
  }

  delete() {
    this.deleteWarning().subscribe(() => {
      this.modalController.dismiss(this.quote, 'delete');
    });
  }

  cancel() {
    this.modalController.dismiss(null, 'cancel');
  }

  checkIfSuperOperatoreInMacroItem($event: {
    macroItemId: number | undefined;
  }) {
    return !!this.identity.companies?.some((c) =>
      c.costCenters?.some((cc) =>
        cc.macroItems?.some(
          (m) => m.superOperatore && m.id === $event.macroItemId
        )
      )
    );
  }

  private getAssignee(
    assignees: UserSummaryMacroItems[] = [],
    costCenter: CostCenterSummary,
    identity: Identity,
    quote?: Quote
  ) {
    if (quote?.assignee) {
      return quote.assignee;
    }
    if (quote?.macroItem || quote?.macroItemId != null) {
      const macroItemId = quote?.macroItem?.id ?? quote?.macroItemId;
      return assignees.find((a) =>
        a.budgetMacroItems?.find((b) => b.id === macroItemId)
      );
    }

    if (costCenter.role === RoleEnum.Operatore) {
      return undefined;
    }

    return { id: identity.id } as UserSummaryMacroItems;
  }

  private deleteWarning() {
    return from(
      this.alertController.create({
        htmlAttributes: { 'data-cy': 'Delete confirmation' },
        mode: 'ios',
        header: this.translateService.instant('messages.confirm.warning'),
        subHeader: this.translateService.instant(
          'messages.confirm.deleteDraftQuote'
        ),
        buttons: [
          {
            text: this.translateService.instant('messages.confirm.cancel'),
            role: 'cancel',
          },
          {
            text: this.translateService.instant('messages.confirm.confirm'),
            role: 'confirm',
          },
        ],
      })
    ).pipe(
      tap((alert) => alert.present()),
      switchMap((alert) => alert.onDidDismiss()),
      filter((event) => event.role === 'confirm')
    );
  }

  private createOverBudgetAlertDialog$() {
    return from(
      this.alertController.create({
        header: this.translateService.instant(
          'pages.approvals.sendAndApproveQuoteOverBudgetAlert.header'
        ),
        message: this.translateService.instant(
          'pages.approvals.sendAndApproveQuoteOverBudgetAlert.message'
        ),
        buttons: [
          this.translateService.instant(
            'pages.approvals.sendAndApproveQuoteOverBudgetAlert.confirm'
          ),
        ],
      })
    ).pipe(
      switchMap((alert) => from(alert.present()).pipe(map(() => alert))),
      switchMap((alert) => from(alert.onWillDismiss()))
    );
  }

  private noProspectiveAssigneesDialog$() {
    return from(
      this.alertController.create({
        htmlAttributes: { 'data-cy': 'No Prospective Assignees Modal' },
        header: this.translateService.instant(
          'pages.insert.alert.noProspectiveAssignees.title'
        ),
        message: this.translateService.instant(
          'pages.insert.alert.noProspectiveAssignees.description'
        ),
        buttons: [
          {
            text: this.translateService.instant(
              'pages.insert.alert.noProspectiveAssignees.cancel'
            ),
            role: 'cancel',
          },
          {
            text: this.translateService.instant(
              'pages.insert.alert.noProspectiveAssignees.saveAsDraft'
            ),
            role: 'confirm',
          },
        ],
      })
    ).pipe(
      tap((alert) => alert.present()),
      switchMap((alert) => alert.onDidDismiss()),
      filter((event) => event.role === 'confirm')
    );
  }
}
