import { Injectable } from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { getValueOrError } from '@front-end/core/utils';

@Injectable({
  providedIn: 'root',
})
export class AnimationsService {
  constructor(private animationController: AnimationController) {}

  slideLeftToRight = (baseEl: HTMLIonModalElement) => {
    const root = getValueOrError(baseEl.shadowRoot);

    const backdropAnimation = this.animationController
      .create()
      .addElement(getValueOrError(root.querySelector('ion-backdrop')))
      .fromTo('opacity', '0', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationController
      .create()
      .addElement(getValueOrError(root.querySelector('.modal-wrapper')))
      .keyframes([
        { offset: 0, opacity: '0', transform: 'translateX(-100%)' },
        { offset: 1, opacity: '1', transform: 'translateX(0%)' },
      ]);

    return this.animationController
      .create()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(150)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  slideRightToLeft = (baseEl: HTMLIonModalElement) => {
    return this.slideLeftToRight(baseEl).direction('reverse');
  };
}
