import { Injectable } from '@angular/core';
import { concat, first, map, Observable, ReplaySubject } from 'rxjs';
import { CostCenterSummary, Identity } from '@libs/core/api';

import { LocalStorageService } from './storage.service';
import { NgxAuthService } from 'ngx-auth-utils';

@Injectable({
  providedIn: 'root',
})
export class CostCentersService {
  private readonly localStorageKey = 'teleconsulting-stp--cost-center';

  private readonly user$: Observable<Identity> = this.authService.state.pipe(
    first()
  );

  private readonly initialCostCenter$ = this.user$.pipe(
    map((user) =>
      this.getInitialCostCenter(user, this.readStoredCostCenterId())
    )
  );

  private latestCostCenter$ = new ReplaySubject<CostCenterSummary>(1);

  /**
   * When no cost-center is selected, this throws an error.
   */
  public selectedCostCenter$ = concat(
    this.initialCostCenter$,
    this.latestCostCenter$.asObservable()
  );

  constructor(
    private authService: NgxAuthService,
    private localStorageService: LocalStorageService
  ) {}

  selectCostCenter(costCenter: CostCenterSummary): void {
    this.latestCostCenter$.next(costCenter);
    this.storeCostCenterId(costCenter);
  }

  getInitialCostCenter(
    user: Identity | undefined,
    costCenterId: number | undefined
  ): CostCenterSummary {
    if (!user || !costCenterId) {
      this.clearStoredCostCenterId();
      throw new Error('invalid-user-or-costcenter');
    }
    const company = user.companies?.find((c) =>
      c.costCenters?.some((cc) => cc.id === costCenterId)
    );

    const costCenter = company?.costCenters?.find(
      (cc) => cc.id === costCenterId
    );

    if (!costCenter) {
      this.clearStoredCostCenterId();
      throw new Error('initial-costcenter-not-found');
    }

    return costCenter;
  }

  private storeCostCenterId(costCenter: CostCenterSummary) {
    this.localStorageService.setItem(this.localStorageKey, `${costCenter.id}`);
  }

  private clearStoredCostCenterId() {
    this.localStorageService.removeItem(this.localStorageKey);
  }

  private readStoredCostCenterId(): number | undefined {
    const value = this.localStorageService.getItem(this.localStorageKey);
    return value == null ? undefined : Number(value);
  }
}
