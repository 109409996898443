<ion-accordion-group>
  <ion-accordion *ngFor="let listOptions of _listOptions; let listIndex = index" data-cy="Macro voice item">
    <ion-item slot="header" class="fs-15" *ngIf="listOptions.label">
      <ion-label class="ion-no-padding ion-no-margin font-medium">{{ listOptions.label | translate | uppercase }}</ion-label>
    </ion-item>
    <ion-item
      *ngFor="let option of listOptions.options; let optionIndex = index"
      mode="ios"
      lines="none"
      slot="content"
      class="fs-14 font-normal ion-no-padding ion-margin-horizontal"
    >
      <ion-checkbox
        slot="start"
        data-cy="Checkbox item"
        [formControl]="formArray.at(_incrementalLengthsOfOptions[listIndex] + optionIndex)"
        (ionChange)="onCheckboxChange(option, $event)"
      ></ion-checkbox>
      <ng-template
        *ngIf="templateRef; else label"
        [ngTemplateOutlet]="templateRef"
        [ngTemplateOutletContext]="{ $implicit: option }"
      ></ng-template>

      <ng-template #label>
        <ion-label>
          <span class="font-regular">{{ option.label | translate }}</span>
        </ion-label>
      </ng-template>
    </ion-item>
  </ion-accordion>
</ion-accordion-group>
