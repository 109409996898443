import { Injectable } from '@angular/core';
import {
  BudgetItem,
  CostCenterSummary,
  Identity,
  OptionHandledWithComment,
  Quote,
  QuoteService,
  QuoteSummary,
  RoleEnum,
  UserService,
} from '@libs/core/api';
import {
  BehaviorSubject,
  connect,
  filter,
  first,
  forkJoin,
  from,
  iif,
  map,
  merge,
  Observable,
  of,
  switchMap,
  switchMapTo,
  tap,
} from 'rxjs';
import { OverlayEventDetail } from '@ionic/core';
import { getValueOrError } from '@front-end/core/utils';
import {
  ApiFiltersService,
  CostCentersService,
} from '@front-end/core/services';
import {
  CaporepartoModalOutput,
  CaporepartoQuoteDetailModalComponent,
  LoadingService,
  QuoteDetailModalComponent,
  ResponsabileModalOutput,
  ResponsabileQuoteDetailModalComponent,
  SupervisoreHandledQuoteDetailModalComponent,
  SupervisoreModalOutput,
  SupervisoreQuoteDetailModalComponent,
  ToastService,
} from '@libs/ui/uikit';
import { ModalController } from '@ionic/angular';
import { InsertQuoteSentModalComponent } from '../insert/components/insert-quote-sent-modal/insert-quote-sent-modal.component';
import { InsertQuoteDraftedModalComponent } from '../insert/components/insert-quote-drafted-modal/insert-quote-drafted-modal.component';
import { Router } from '@angular/router';
import { InsertQuoteModalComponent } from '../insert/components/insert-quote-modal/insert-quote-modal.component';
import { NgxAuthService } from 'ngx-auth-utils';

@Injectable({
  providedIn: 'root',
})
export class QuotesCreationService {
  readonly costCenter$ = this.costCentersService.selectedCostCenter$.pipe(
    first()
  );
  readonly responsabileBudgetLimit$ = this.costCenter$.pipe(
    switchMap((costCenter) =>
      iif(
        () =>
          [RoleEnum.Responsabile, RoleEnum.Supervisore].includes(
            costCenter?.role as RoleEnum
          ),
        this.userService
          .costCenterResponsabileBudgetLimit({
            id: getValueOrError(costCenter?.id),
          })
          .pipe(this.toastService.catchAndThrowErrorWithToast()),
        of(undefined)
      )
    )
  );
  readonly identity$: Observable<Identity> = this.authService.state.pipe(
    first()
  );
  readonly openedEditQuoteModal$ =
    new BehaviorSubject<HTMLIonModalElement | null>(null);

  constructor(
    private quoteService: QuoteService,
    private costCentersService: CostCentersService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private userService: UserService,
    private apiFiltersService: ApiFiltersService,
    private router: Router,
    private authService: NgxAuthService
  ) {}

  openQuoteDetails(quote: Quote): Observable<void> {
    return forkJoin([this.costCenter$.pipe(first()), this.identity$]).pipe(
      switchMap(([costCenter, identity]) =>
        this.showQuoteDetailModal(costCenter, quote, identity)
      )
    );
  }

  openQuoteDetailsShowingOverBudgetAmount(quote: Quote): Observable<void> {
    return forkJoin([this.costCenter$.pipe(first()), this.identity$]).pipe(
      switchMap(([costCenter, identity]) =>
        this.showQuoteDetailModal(costCenter, quote, identity, true)
      )
    );
  }

  private showQuoteDetailModal(
    costCenter: CostCenterSummary,
    quote: Quote,
    identity: Identity,
    showOverBudgetAmount = false
  ): Observable<void> {
    return from(
      this.modalController.create({
        component: QuoteDetailModalComponent,
        mode: 'md',
        componentProps: {
          costCenter,
          quote,
          identity,
          showOverBudgetAmount,
        },
      })
    ).pipe(switchMap((modal) => from(modal.present())));
  }

  retrieveQuote(quote: QuoteSummary, hideRejected = false): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService
          .quoteRetrive({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
            $hideRejected: hideRejected,
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(
              'messages.errors.retrieveQuote'
            ),
            this.loadingService.withLoading()
          )
      )
    );
  }

  caporepartoPreApproveQuote(quote: Quote): Observable<Quote> {
    return this.openQuotePreApprovationModal(quote).pipe(
      connect((source) =>
        merge(
          source.pipe(
            filter((modal) => modal.role === 'pre-approve'),
            switchMap(({ data }) => {
              return this.caporepartoSendPreApproveQuote(
                quote,
                getValueOrError(data?.preApprovedOptions)
              );
            })
          ),
          source.pipe(
            filter((modal) => modal.role === 'pre-reject'),
            switchMap(() => {
              return this.caporepartoSendPreRejectQuote(quote);
            })
          )
        )
      )
    );
  }

  supervisoreManageOverbudget(quote: Quote): Observable<Quote> {
    return this.openQuoteOverbudgetModal(quote).pipe(
      filter((modal) => modal.role === 'over-budget-for-approval'),
      switchMap(({ data }) => {
        return this.supervisoreSendManageOverbudget(
          quote,
          getValueOrError(data?.isApproved)
        );
      })
    );
  }

  supervisoreViewHandledDetail(
    quote: Quote
  ): Observable<OverlayEventDetail<void>> {
    return this.openQuoteHandledOverbudgetModal(quote);
  }

  approveOrRejectQuote(quote: Quote): Observable<Quote> {
    return this.openQuoteApprovationModal(quote).pipe(
      connect((source) =>
        merge(
          source.pipe(
            filter((modal) => modal.role === 'approve'),
            switchMap(({ data }) =>
              this.assignBudgetItemToQuote(
                quote,
                getValueOrError(data?.budgetItem)
              ).pipe(map(() => ({ data })))
            ),
            switchMap(({ data }) =>
              this.approveQuote(quote, getValueOrError(data?.option))
            )
          ),
          source.pipe(
            filter((modal) => modal.role === 'reject'),
            switchMap(({ data }) =>
              this.rejectQuote(quote, getValueOrError(data?.rejectedOptions))
            )
          )
        )
      )
    );
  }

  editQuote(quoteSummary?: QuoteSummary) {
    const closePreviousModal$ = this.openedEditQuoteModal$.pipe(
      first(),
      switchMap((modal) => {
        if (modal) {
          return from(modal.dismiss());
        }

        return of(null);
      })
    );

    return closePreviousModal$.pipe(
      switchMap(() =>
        this.editQuoteModal(quoteSummary).pipe(
          connect((source) =>
            merge(
              source.pipe(
                filter((modal) => modal.role === 'send'),
                switchMap((modal) => this.sendQuote(modal.data as Quote))
              ),
              source.pipe(
                filter((modal) => modal.role === 'draft'),
                switchMap((modal) => this.draftQuote(modal.data as Quote))
              ),
              source.pipe(
                filter((modal) => modal.role === 'draftWithBudgetItem'),
                switchMap((modal) =>
                  this.draftWithBudgetItem(
                    modal.data as { quote: Quote; budgetItem: BudgetItem }
                  )
                )
              ),
              source.pipe(
                filter((modal) => modal.role === 'delete'),
                switchMap((modal) => this.deleteDraftQuote(modal.data as Quote))
              ),
              source.pipe(
                filter((modal) => modal.role === 'sendAndApprove'),
                switchMap((modal) =>
                  this.sendAndApproveQuote(
                    modal.data as { quote: Quote; budgetItem: BudgetItem }
                  )
                )
              )
            )
          )
        )
      )
    );
  }

  private editQuoteModal(
    quoteSummary?: QuoteSummary
  ): Observable<OverlayEventDetail<Partial<Quote>>> {
    const assignees$ = this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.userService.costCenterUserList({
          id: getValueOrError(costCenter.id),
          $filter: this.apiFiltersService.getUserRoleFilter(
            RoleEnum.Caporeparto
          ),
          $myMacroItems: true,
        })
      )
    );
    const quote$ = iif(
      () => !!quoteSummary,
      this.retrieveQuote(quoteSummary as QuoteSummary),
      of(null)
    );
    const identity$ = this.authService.state.pipe(first());

    return forkJoin([
      assignees$,
      this.costCenter$,
      identity$,
      quote$,
      this.responsabileBudgetLimit$,
    ]).pipe(
      this.loadingService.withLoading(),
      switchMap(([assignees, costCenter, identity, quote, budgetLimit]) => {
        return from(
          this.modalController.create({
            component: InsertQuoteModalComponent,
            componentProps: {
              assignees,
              costCenter,
              identity,
              quote,
              budgetLimit: budgetLimit?.budgetLimit,
            },
          })
        ).pipe(
          tap((modal) => modal.present()),
          tap((modal) => this.openedEditQuoteModal$.next(modal)),
          switchMap((modal) => modal.onWillDismiss())
        );
      })
    );
  }

  private openQuotePreApprovationModal(
    quote: Quote
  ): Observable<OverlayEventDetail<Partial<CaporepartoModalOutput>>> {
    return forkJoin([this.costCenter$.pipe(first()), this.identity$]).pipe(
      switchMap(([costCenter, identity]) =>
        from(
          this.modalController.create({
            component: CaporepartoQuoteDetailModalComponent,
            componentProps: {
              costCenter,
              quote,
              identity,
            },
          })
        ).pipe(
          switchMap((modal) => from(modal.present()).pipe(map(() => modal))),
          switchMap((modal) => from(modal.onWillDismiss()))
        )
      )
    );
  }

  private openQuoteOverbudgetModal(
    quote: Quote
  ): Observable<OverlayEventDetail<Partial<SupervisoreModalOutput>>> {
    return forkJoin([
      this.costCenter$.pipe(first()),
      this.responsabileBudgetLimit$,
      this.identity$,
    ]).pipe(
      switchMap(([costCenter, budgetLimit, identity]) =>
        from(
          this.modalController.create({
            component: SupervisoreQuoteDetailModalComponent,
            componentProps: {
              costCenter,
              quote,
              identity,
              budgetLimit: budgetLimit?.budgetLimit,
            },
          })
        ).pipe(
          switchMap((modal) => from(modal.present()).pipe(map(() => modal))),
          switchMap((modal) => from(modal.onWillDismiss()))
        )
      )
    );
  }

  private openQuoteHandledOverbudgetModal(
    quote: Quote
  ): Observable<OverlayEventDetail<void>> {
    return forkJoin([this.costCenter$.pipe(first()), this.identity$]).pipe(
      switchMap(([costCenter, identity]) =>
        from(
          this.modalController.create({
            component: SupervisoreHandledQuoteDetailModalComponent,
            componentProps: {
              costCenter,
              quote,
              identity,
            },
          })
        ).pipe(
          switchMap((modal) => from(modal.present()).pipe(map(() => modal))),
          switchMap((modal) => from(modal.onWillDismiss()))
        )
      )
    );
  }

  private openQuoteApprovationModal(
    quote: Quote
  ): Observable<OverlayEventDetail<Partial<ResponsabileModalOutput>>> {
    return forkJoin([
      this.costCenter$.pipe(first()),
      this.responsabileBudgetLimit$,
      this.identity$,
    ]).pipe(
      switchMap(([costCenter, budgetLimit, identity]) =>
        from(
          this.modalController.create({
            component: ResponsabileQuoteDetailModalComponent,
            componentProps: {
              costCenter,
              quote,
              identity,
              budgetLimit: budgetLimit?.budgetLimit,
            },
          })
        ).pipe(
          switchMap((modal) => from(modal.present()).pipe(map(() => modal))),
          switchMap((modal) => from(modal.onWillDismiss()))
        )
      )
    );
  }

  private sentQuoteModal(): Observable<OverlayEventDetail<void>> {
    return from(
      this.modalController.create({
        component: InsertQuoteSentModalComponent,
      })
    ).pipe(
      tap((modal) => modal.present()),
      switchMap((modal) => modal.onWillDismiss()),
      tap(() => this.router.navigateByUrl('/home/quotes/sent'))
    );
  }

  private draftedQuoteModal(): Observable<OverlayEventDetail<void>> {
    return from(
      this.modalController.create({
        component: InsertQuoteDraftedModalComponent,
      })
    ).pipe(
      tap((modal) => modal.present()),
      switchMap((modal) => modal.onWillDismiss()),
      tap(() => this.router.navigateByUrl('/home/quotes/drafted'))
    );
  }

  private sendQuote(quote: Quote) {
    return iif(
      () => !quote.id,
      this.createQuote(quote),
      this.updateQuote(quote)
    ).pipe(
      switchMap((quote) => this.preApproveQuote(quote)),
      switchMapTo(this.sentQuoteModal())
    );
  }

  private draftQuote(quote: Quote) {
    return iif(
      () => !quote.id,
      this.createQuote(quote),
      this.updateQuote(quote)
    ).pipe(switchMapTo(this.draftedQuoteModal()));
  }

  private draftWithBudgetItem($data: { quote: Quote; budgetItem: BudgetItem }) {
    delete $data.quote.budgetItem;
    return iif(
      () => !$data.quote.id,
      this.createQuote($data.quote),
      this.updateQuote($data.quote)
    ).pipe(
      switchMap((quote) =>
        this.assignBudgetItemToQuote(
          quote,
          getValueOrError($data?.budgetItem)
        ).pipe(map(() => quote))
      ),
      switchMapTo(this.draftedQuoteModal())
    );
  }

  private preApproveQuote(quote: Quote): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) => {
        if (costCenter.role === RoleEnum.Operatore) {
          return this.quoteService
            .requestQuotePreapproval({
              id: Number(costCenter.id),
              quoteId: Number(quote.id),
            })
            .pipe(
              this.toastService.catchAndThrowErrorWithToast(
                'messages.errors.requireQuoteApproval'
              ),
              this.loadingService.withLoading()
            );
        }

        return this.quoteService
          .preApproveQuote({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
            body: {
              options: quote.options.map((option) => {
                return { id: getValueOrError(option.id) };
              }),
            },
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(
              'messages.errors.confirmQuote'
            ),
            this.loadingService.withLoading()
          );
      })
    );
  }

  private createQuote(quote: Quote): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService
          .quoteCreate({
            id: Number(costCenter.id),
            body: quote,
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(
              'messages.errors.createQuote'
            ),
            this.loadingService.withLoading()
          )
      )
    );
  }

  private updateQuote(quote: Quote): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService
          .quoteUpdate({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
            body: quote,
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(
              'messages.errors.updateQuote'
            ),
            this.loadingService.withLoading()
          )
      )
    );
  }

  private deleteDraftQuote(quote: Quote): Observable<void> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService
          .draftQuoteDelete({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(
              'messages.errors.deleteQuote'
            ),
            this.loadingService.withLoading(),
            tap(() => this.toastService.success('messages.success.deleteQuote'))
          )
      )
    );
  }

  private caporepartoSendPreApproveQuote(
    quote: Quote,
    preApprovedOptions: Array<OptionHandledWithComment>
  ): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService
          .preApproveQuote({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
            body: {
              options: preApprovedOptions,
            },
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(),
            this.loadingService.withLoading(),
            tap(() =>
              this.toastService.success('messages.success.quoteManaged')
            )
          )
      )
    );
  }

  private caporepartoSendPreRejectQuote(quote: Quote): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService
          .preRejectQuote({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(),
            this.loadingService.withLoading(),
            tap(() =>
              this.toastService.success('messages.success.quoteManaged')
            )
          )
      )
    );
  }

  private approveQuote(
    quote: Quote,
    approvedOption: OptionHandledWithComment,
    showSuccessToast = true
  ): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService
          .approveQuote({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
            body: approvedOption,
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(),
            this.loadingService.withLoading(),
            tap(() => {
              if (showSuccessToast) {
                this.toastService.success('messages.success.quoteManaged');
              }
            })
          )
      )
    );
  }

  private supervisoreSendManageOverbudget(
    quote: Quote,
    isApproved: boolean
  ): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        iif(
          () => isApproved,
          this.quoteService.acceptOverBudgetQuote({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
          }),
          this.quoteService.rejectOverBudgetQuote({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
          })
        ).pipe(
          this.toastService.catchAndThrowErrorWithToast(),
          this.loadingService.withLoading(),
          tap(() => this.toastService.success('messages.success.quoteManaged'))
        )
      )
    );
  }

  private rejectQuote(
    quote: Quote,
    rejectedOptions: Array<OptionHandledWithComment>
  ): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService
          .rejectQuote({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
            body: {
              options: rejectedOptions,
            },
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(),
            this.loadingService.withLoading(),
            tap(() =>
              this.toastService.success('messages.success.quoteManaged')
            )
          )
      )
    );
  }

  private assignBudgetItemToQuote(
    quote: Quote,
    budgetItem: BudgetItem
  ): Observable<Quote> {
    return this.costCenter$.pipe(
      switchMap((costCenter) =>
        this.quoteService
          .assignBudgetVoiceToQuote({
            id: Number(costCenter.id),
            quoteId: Number(quote.id),
            body: { budgetItem: getValueOrError(budgetItem.id) },
          })
          .pipe(
            this.toastService.catchAndThrowErrorWithToast(),
            this.loadingService.withLoading()
          )
      )
    );
  }

  private sendAndApproveQuote($data: {
    quote: Quote;
    budgetItem: BudgetItem;
  }): Observable<OverlayEventDetail<void>> {
    delete $data.quote.budgetItem;

    return iif(
      () => !$data.quote.id,
      this.createQuote($data.quote),
      this.updateQuote($data.quote)
    ).pipe(
      switchMap((quote) =>
        this.assignBudgetItemToQuote(
          quote,
          getValueOrError($data?.budgetItem)
        ).pipe(map(() => quote))
      ),
      switchMap((quote) =>
        this.approveQuote(quote, getValueOrError(quote?.options[0]), false)
      ),
      switchMapTo(this.sentQuoteModal())
    );
  }
}
