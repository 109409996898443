/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthTokens } from '../models/auth-tokens';
import { Identity } from '../models/identity';
import { RecoveryPasswordConfirm } from '../models/recovery-password-confirm';
import { RecoveryPasswordRequest } from '../models/recovery-password-request';
import { TokenLoginRequest } from '../models/token-login-request';

/**
 * Login, identity
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation login
   */
  static readonly LoginPath = '/auth/token';

  /**
   * (OLD) Retrieve authentication tokens with the given credentials.
   *
   * User authentication
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  login$Response(params?: {
    'Stp-Refresh-Token'?: string;
    context?: HttpContext;
    body?: TokenLoginRequest;
  }): Observable<StrictHttpResponse<AuthTokens>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.LoginPath, 'post');
    if (params) {
      rb.header('Stp-Refresh-Token', params['Stp-Refresh-Token'], {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthTokens>;
        })
      );
  }

  /**
   * (OLD) Retrieve authentication tokens with the given credentials.
   *
   * User authentication
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  login(params?: {
    'Stp-Refresh-Token'?: string;
    context?: HttpContext;
    body?: TokenLoginRequest;
  }): Observable<AuthTokens> {
    return this.login$Response(params).pipe(
      map((r: StrictHttpResponse<AuthTokens>) => r.body as AuthTokens)
    );
  }

  /**
   * Path part for operation identity
   */
  static readonly IdentityPath = '/api/mobile/user/identity/';

  /**
   * User identity.
   *
   * User identity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identity()` instead.
   *
   * This method doesn't expect any request body.
   */
  identity$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Identity>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AuthService.IdentityPath,
      'get'
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Identity>;
        })
      );
  }

  /**
   * User identity.
   *
   * User identity
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identity(params?: { context?: HttpContext }): Observable<Identity> {
    return this.identity$Response(params).pipe(
      map((r: StrictHttpResponse<Identity>) => r.body as Identity)
    );
  }

  /**
   * Path part for operation resetPassword
   */
  static readonly ResetPasswordPath = '/api/mobile/user/password/recovery/';

  /**
   * Request a password reset.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword$Response(params: {
    context?: HttpContext;
    body: RecoveryPasswordRequest;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AuthService.ResetPasswordPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Request a password reset.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword(params: {
    context?: HttpContext;
    body: RecoveryPasswordRequest;
  }): Observable<void> {
    return this.resetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation confirmResetPassword
   */
  static readonly ConfirmResetPasswordPath =
    '/api/mobile/user/password/recovery/{token}/';

  /**
   * Confirm the password reset.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmResetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmResetPassword$Response(params: {
    /**
     * The token to reset the password
     */
    token: string;
    context?: HttpContext;
    body: RecoveryPasswordConfirm;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AuthService.ConfirmResetPasswordPath,
      'post'
    );
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Confirm the password reset.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirmResetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmResetPassword(params: {
    /**
     * The token to reset the password
     */
    token: string;
    context?: HttpContext;
    body: RecoveryPasswordConfirm;
  }): Observable<void> {
    return this.confirmResetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
