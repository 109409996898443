<ion-buttons class="ion-flex ion-justify-content-between">
  <ion-button (click)="reject()" [ngClass]="{ rejected: approved === false || forceColour === true }" mode="ios" data-cy="Reject button">
    <ion-icon slot="start" [name]="approved === false ? 'close-circle' : 'close-circle-outline'"></ion-icon>
    {{ rejectText }}
  </ion-button>
  <ion-button (click)="approve()" [ngClass]="{ approved: approved === true || forceColour === true }" mode="ios" data-cy="Approve button">
    <ion-icon slot="start" [name]="approved === true ? 'checkmark-circle' : 'checkmark-circle-outline'"></ion-icon>
    {{ approveText }}
  </ion-button>
</ion-buttons>
