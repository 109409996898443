import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  BudgetItemSummary,
  BudgetMacroItem,
  CostCenterSummary,
  MacroBudgetItemsService,
} from '@libs/core/api';
import { filter, first, from, map, switchMap } from 'rxjs';
import { ModalController } from '@ionic/angular';
import {
  AnimationsService,
  LoadingService,
  ToastService,
} from '../../services';
import { CostCentersService } from '@front-end/core/services';
import { IonicBaseComponent } from '@front-end/core/ionic-hooks';
import { getValueOrError } from '@front-end/core/utils';
import { BudgetItemSelectModalComponent } from '../budget-item-select-modal/budget-item-select-modal.component';

@Component({
  selector: 'ui-budget-item-select',
  templateUrl: './budget-item-select.component.html',
  styleUrls: ['./budget-item-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BudgetItemSelectComponent,
    },
  ],
})
export class BudgetItemSelectComponent
  extends IonicBaseComponent
  implements ControlValueAccessor
{
  @Input() placeholder?: string;
  @Input() costCenter!: CostCenterSummary;
  @Input() macroBudgetItems: BudgetMacroItem[] = [];

  selectedBudgetItem?: BudgetItemSummary;

  touched = false;
  disabled = false;

  constructor(
    private modalController: ModalController,
    private macroBudgetItemsService: MacroBudgetItemsService,
    private costCentersService: CostCentersService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private animationService: AnimationsService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = (selectedBudgetItem: BudgetItemSummary) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(selectedBudgetItem: BudgetItemSummary) {
    this.selectedBudgetItem = selectedBudgetItem;
  }

  registerOnChange(onChange: never) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: never) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  openModal() {
    this.macroBudgetItemsService
      .macroBudgetItemList({
        id: getValueOrError(this.costCenter.id),
        $macroItems:
          this.macroBudgetItems.length > 0
            ? this.macroBudgetItems.map((macroItem) =>
                getValueOrError(macroItem.id)
              )
            : undefined,
      })
      .pipe(
        first(),
        this.loadingService.withLoading(),
        this.toastService.catchErrorWithToast(),
        switchMap((macroItems) =>
          from(
            this.modalController.create({
              component: BudgetItemSelectModalComponent,
              mode: 'md',
              componentProps: {
                macroItems: macroItems,
                selected: this.selectedBudgetItem,
              },
              enterAnimation: this.animationService.slideLeftToRight,
              leaveAnimation: this.animationService.slideRightToLeft,
            })
          )
        ),
        switchMap((modal) => from(modal.present()).pipe(map(() => modal))),
        switchMap((modal) => from(modal.onWillDismiss())),
        filter(({ role }) => role === 'confirm')
      )
      .subscribe(({ data }) => {
        let budgetItem = undefined;
        if (data && data.length > 0) {
          budgetItem = data[0];
        }
        this.writeValue(budgetItem);
        this.onChange(budgetItem);
        this.changeDetector.markForCheck();
      });
  }
}
