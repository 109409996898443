<ion-segment *ngIf="options.length > 1" [scrollable]="true" mode="ios" [value]="option" (ionChange)="change($event)">
  <ion-segment-button
    *ngFor="let _ of options; let index = index"
    [value]="index"
    [attr.data-cy]="option === index ? 'Option ' + (index + 1) + ' is selected' : 'Option ' + (index + 1)"
    class="ion-flex"
  >
    <span
      class="status"
      *ngIf="caporepartoApprovals && caporepartoApprovals[index]?.approved !== undefined"
      [ngClass]="{ rejected: caporepartoApprovals[index].approved === false, approved: caporepartoApprovals[index].approved === true }"
    ></span>
    <ion-label class="text-uppercase">{{ 'quote.summary.opt' | translate }} {{ index + 1 }}</ion-label>
  </ion-segment-button>
</ion-segment>
