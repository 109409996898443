<ion-header mode="ios">
  <ion-toolbar color="secondary">
    <ion-title class="text-grey-ultra-dark">{{ 'pages.quotes.detail.title' | translate }}</ion-title>

    <ion-buttons slot="start" *ngIf="costCenter.role === RoleEnum.GodamAdministrator">
      <ion-button [attr.aria-label]="'pages.quotes.modal.delete' | translate" (click)="delete()" data-cy="Delete quote">
        {{ 'pages.quotes.detail.delete' | translate }}
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ui-current-cdc [costCenter]="costCenter"></ui-current-cdc>
</ion-header>

<ion-content class="ion-padding">
  <ui-quote-detail [identity]="identity" [detail]="quote" [showOverBudgetAmount]="showOverBudgetAmount"></ui-quote-detail>
</ion-content>
