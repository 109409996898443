/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BudgetMacroItemComplete } from '../models/budget-macro-item-complete';

@Injectable({
  providedIn: 'root',
})
export class MacroBudgetItemsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation macroBudgetItemList
   */
  static readonly MacroBudgetItemListPath =
    '/api/mobile/costcenter/{id}/macro-budget-items/';

  /**
   * Get all macro budget items of a cost center.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `macroBudgetItemList()` instead.
   *
   * This method doesn't expect any request body.
   */
  macroBudgetItemList$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * Filtra per id di macro budget
     */
    $macroItems?: Array<number>;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<BudgetMacroItemComplete>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MacroBudgetItemsService.MacroBudgetItemListPath,
      'get'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.query('$macroItems', params['$macroItems'], { explode: false });
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<BudgetMacroItemComplete>>;
        })
      );
  }

  /**
   * Get all macro budget items of a cost center.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `macroBudgetItemList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  macroBudgetItemList(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * Filtra per id di macro budget
     */
    $macroItems?: Array<number>;
    context?: HttpContext;
  }): Observable<Array<BudgetMacroItemComplete>> {
    return this.macroBudgetItemList$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<BudgetMacroItemComplete>>) =>
          r.body as Array<BudgetMacroItemComplete>
      )
    );
  }
}
