import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import {
  BudgetItemSummary,
  CostCenterSummary,
  Identity,
  OptionHandledWithComment,
  Quote,
  QuoteOption,
  QuoteStatusEnum,
} from '@libs/core/api';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastService } from '../../services';
import { EMPTY, from, iif, map, of, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

export interface ResponsabileModalOutput {
  rejectedOptions: Array<OptionHandledWithComment>;
  option: OptionHandledWithComment;
  budgetItem: BudgetItemSummary;
}

interface QuoteOptionCommentForm {
  id: FormControl<number | null>;
  comment: FormControl<string | null | undefined>;
}

@Component({
  selector: 'ui-responsabile-quote-detail-modal',
  templateUrl: './responsabile-quote-detail-modal.component.html',
  styleUrls: ['./responsabile-quote-detail-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsabileQuoteDetailModalComponent {
  public commentForm?: FormGroup<{
    options: FormArray<FormGroup<QuoteOptionCommentForm>>;
  }>;

  readonly form = this.formBuilder.group({
    budgetItem: this.formBuilder.control<BudgetItemSummary | undefined>(
      undefined,
      [Validators.required]
    ),
  });

  @Input() costCenter!: CostCenterSummary;
  _quote!: Quote;
  @Input() set quote(quote: Quote) {
    this._quote = quote;
    this.commentForm = this.formBuilder.group({
      options: new FormArray<FormGroup<QuoteOptionCommentForm>>(
        this._quote.options.map((option: QuoteOption) => {
          return this.formBuilder.group<QuoteOptionCommentForm>({
            id: this.formBuilder.control<number>(option.id || 0, [
              Validators.required,
            ]),
            comment: this.formBuilder.control<string | undefined>(''),
          });
        })
      ),
    });
  }
  @Input() identity!: Identity;
  @Input() budgetLimit?: number;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private alertController: AlertController,
    private translateService: TranslateService
  ) {}

  cancel() {
    this.modalController.dismiss(undefined, 'cancel');
  }

  approve(option: QuoteOption): void {
    if (this.form.invalid) {
      this.toastService.error('messages.errors.budgetItemNotSelected');
      return;
    }

    const budgetItem = this.form.controls.budgetItem.value as BudgetItemSummary;

    const overBudgetAlertDialog$ = from(
      this.alertController.create({
        header: this.translateService.instant(
          'pages.approvals.approveQuoteOverBudgetAlert.header'
        ),
        message: this.translateService.instant(
          'pages.approvals.approveQuoteOverBudgetAlert.message'
        ),
        buttons: [
          this.translateService.instant(
            'pages.approvals.approveQuoteOverBudgetAlert.confirm'
          ),
        ],
      })
    ).pipe(
      switchMap((alert) => from(alert.present()).pipe(map(() => alert))),
      switchMap((alert) => from(alert.onWillDismiss()))
    );

    iif(
      () => this.budgetLimit != null && option.price > this.budgetLimit,
      overBudgetAlertDialog$,
      of(EMPTY)
    ).subscribe(() => {
      this.modalController.dismiss(
        {
          option: {
            id: option.id,
            comment: this.commentForm?.value.options?.find(
              (o) => (o.id = option.id)
            )?.comment,
          },
          budgetItem: budgetItem,
        },
        'approve'
      );
    });
  }

  rejectAll(): void {
    const rejectAlert$ = from(
      this.alertController.create({
        header: this.translateService.instant(
          'pages.approvals.rejectQuoteAlert.header'
        ),
        message: this.translateService.instant(
          'pages.approvals.rejectQuoteAlert.message'
        ),
        buttons: [
          {
            text: this.translateService.instant(
              'pages.approvals.rejectQuoteAlert.cancel'
            ),
            role: 'cancel',
          },
          {
            text: this.translateService.instant(
              'pages.approvals.rejectQuoteAlert.confirm'
            ),
            role: 'confirm',
          },
        ],
      })
    ).pipe(
      switchMap((alert) => from(alert.present()).pipe(map(() => alert))),
      switchMap((alert) => from(alert.onWillDismiss())),
      filter(({ role }) => role === 'confirm')
    );

    iif(
      () => this._quote.status === QuoteStatusEnum.PreApproved,
      rejectAlert$,
      of(EMPTY)
    ).subscribe(() => {
      this.modalController.dismiss(
        { rejectedOptions: this.commentForm?.value.options },
        'reject'
      );
    });
  }
}
