import { Directive, ElementRef, Input } from '@angular/core';
import { QuoteStatusEnum } from '@libs/core/api';

@Directive({
  selector: '[uiQuoteStatusColor]',
})
export class QuoteStatusColorDirective {
  readonly waitingForApproval = ['text-grey-dark', 'bg-grey-light'];

  readonly approved = ['text-success', 'bg-success-light'];

  readonly rejected = ['text-danger', 'bg-danger-light'];

  @Input() set uiQuoteStatusColor(value: QuoteStatusEnum) {
    const quoteClasses = {
      [QuoteStatusEnum.Approved]: this.approved,
      [QuoteStatusEnum.OverBudgetAccepted]: this.approved,

      [QuoteStatusEnum.Rejected]: this.rejected,
      [QuoteStatusEnum.PreRejected]: this.rejected,
      [QuoteStatusEnum.OverBudgetRejected]: this.rejected,

      [QuoteStatusEnum.PreApproved]: this.waitingForApproval,
      [QuoteStatusEnum.WaitingForPreApproval]: this.waitingForApproval,
      [QuoteStatusEnum.ApprovedOverBudget]: this.waitingForApproval,
      [QuoteStatusEnum.Draft]: [],
    };
    const statusClasses = quoteClasses[value] ?? '';
    this.elementRef.nativeElement.classList.add(...statusClasses);
  }

  constructor(private elementRef: ElementRef) {}
}
