<div class="scroller">
  <div class="attachments gap-wrapper">
    <div class="attachment" *ngFor="let attachment of option.attachments">
      <div class="no-overflow">
        <ui-media-preview [fileUrl]="attachment" [preview]="true" (openSliderEvent)="openSlider(attachment)"> </ui-media-preview>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showBudgetLimit && budgetLimit != null" class="fs-14 mt-16 text-grey-dark">
  {{ 'quote.summary.budgetLimit' | translate: { price: budgetLimit | fixBackendPrice | currency: 'EUR' } }}
</div>
<div class="fs-18 mt-6 mb-8 flex">
  <div>
    {{ 'quote.summary.price' | translate: { price: option.price | fixBackendPrice | currency: 'EUR' } }}
  </div>
  <ui-budget-badge
    *ngIf="showGenericOverBudgetWarning && budgetLimit != null && budgetLimit < option.price"
    class="ml-16"
    data-cy="Generic over budget warning"
  >
    <ion-icon name="alert-circle" class="fs-16"></ion-icon>
    <span class="fs-11 font-semibold ml-4">{{ 'quote.summary.overBudget' | translate }}</span>
  </ui-budget-badge>
</div>
<ui-budget-badge
  *ngIf="showOverBudgetAmount && !!overBudget && !showGenericOverBudgetWarning"
  class="my-6 w-fit-content"
  data-cy="Over budget warning"
>
  <span class="fs-15 font-medium">+ {{ overBudget | fixBackendPrice | currency: 'EUR':'symbol':'1.0-0' }}</span>
</ui-budget-badge>

<div class="flex flex-column column-gap-4">
  <p *ngFor="let note of option.notes" class="mt-0 fs-14 text-grey-dark">
    {{ 'quote.summary.note' | translate }} {{ note.user?.role | role }}: {{ note.note }}
  </p>
</div>
