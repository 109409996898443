<ng-container *ngIf="file">
  <ng-container [ngSwitch]="file.type">
    <ng-container *ngSwitchCase="'image'">
      <div class="swiper-zoom-container">
        <img alt="Preview" class="image" [src]="file.data" />
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="w-full h-full text-grey-dark">
        <div class="grid place-content-center w-full h-full">
          <div class="grid place-content-center w-full h-full">
            <pdf-viewer
              [src]="file.data"
              [render-text]="true"
              [render-text-mode]="2"
              [original-size]="false"
              [show-all]="true"
              [autoresize]="true"
              (after-load-complete)="onLoadComplete()"
              [rotation]="rotation"
            ></pdf-viewer>

            <ion-button (click)="downloadPDF(file)">
              <ion-icon class="mr-4" name="download-outline"></ion-icon>
              Download PDF
            </ion-button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
