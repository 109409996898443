import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QuoteStatusEnum } from '@libs/core/api';

@Component({
  selector: 'ui-quote-status-badge',
  templateUrl: './quote-status-badge.component.html',
  styleUrls: ['./quote-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteStatusBadgeComponent {
  @Input() status!: QuoteStatusEnum;
}
