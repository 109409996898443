<ion-item class="floating" [ngClass]="class" [lines]="lines" mode="ios">
  <ion-icon *ngIf="icon" [name]="icon" [slot]="iconSlot" [ngClass]="iconClasses"></ion-icon>
  <ion-label *ngIf="showLabel" [position]="labelPosition" color="medium"
    >{{ label + '.label' | translate }} <span *ngIf="required$ | async" class="field-required">*</span>
  </ion-label>
  <ng-content></ng-content>
  <ion-note slot="error" *ngIf="(dirtyOrTouched$ | async) && (firstError$ | async) as error">
    <ng-container *ngIf="error.standard; else customError">{{ 'errors.' + error.key | translate: error.value }}</ng-container>
    <ng-template #customError>
      <span>{{ label + '.errors.' + error.key | translate: error.value }}</span>
    </ng-template>
  </ion-note>
</ion-item>
