import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { QuoteOption } from '@libs/core/api';

@Component({
  selector: 'ui-quote-detail-option',
  templateUrl: './quote-detail-option.component.html',
  styleUrls: ['./quote-detail-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteDetailOptionComponent {
  @Input() budgetLimit?: number | null;
  @Input() option!: QuoteOption;
  @Input() overBudget?: number;
  @Input() showGenericOverBudgetWarning = false;
  @Input() showBudgetLimit = false;
  @Input() showOverBudgetAmount = false;

  @Output() openSliderEvent = new EventEmitter();

  openSlider(attachment: string) {
    this.openSliderEvent.emit(attachment);
  }
}
