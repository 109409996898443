/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { OptionHandledWithComment } from '../models/option-handled-with-comment';
import { Quote } from '../models/quote';
import { QuoteHistory } from '../models/quote-history';
import { QuotePagedList } from '../models/quote-paged-list';
import { QuoteStatusEnum } from '../models/quote-status-enum';

/**
 * Quote
 */
@Injectable({
  providedIn: 'root',
})
export class QuoteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation quoteList
   */
  static readonly QuoteListPath = '/api/mobile/costcenter/{id}/quotes/';

  /**
   * Get all quotes visibile to the user of a cost center.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quoteList()` instead.
   *
   * This method doesn't expect any request body.
   */
  quoteList$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * filtro
     */
    $filter?: string;

    /**
     * filtro
     */
    $status?: Array<QuoteStatusEnum>;

    /**
     * Return the count of total items
     */
    $count?: boolean;

    /**
     * The number of items to be returned
     */
    $top?: number;

    /**
     * The number of items to skip
     */
    $skip?: number;

    /**
     * The sorting criteria for this list
     */
    $orderby?: string;

    /**
     * The format to be returned
     */
    $format?: string;

    /**
     * Hides the Rejected answers from the options array
     */
    $hideRejected?: boolean;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<QuotePagedList>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.QuoteListPath,
      'get'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.query('$filter', params['$filter'], {});
      rb.query('$status', params['$status'], { explode: false });
      rb.query('$count', params['$count'], {});
      rb.query('$top', params['$top'], {});
      rb.query('$skip', params['$skip'], {});
      rb.query('$orderby', params['$orderby'], {});
      rb.query('$format', params['$format'], {});
      rb.query('$hideRejected', params['$hideRejected'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<QuotePagedList>;
        })
      );
  }

  /**
   * Get all quotes visibile to the user of a cost center.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `quoteList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  quoteList(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * filtro
     */
    $filter?: string;

    /**
     * filtro
     */
    $status?: Array<QuoteStatusEnum>;

    /**
     * Return the count of total items
     */
    $count?: boolean;

    /**
     * The number of items to be returned
     */
    $top?: number;

    /**
     * The number of items to skip
     */
    $skip?: number;

    /**
     * The sorting criteria for this list
     */
    $orderby?: string;

    /**
     * The format to be returned
     */
    $format?: string;

    /**
     * Hides the Rejected answers from the options array
     */
    $hideRejected?: boolean;
    context?: HttpContext;
  }): Observable<QuotePagedList> {
    return this.quoteList$Response(params).pipe(
      map((r: StrictHttpResponse<QuotePagedList>) => r.body as QuotePagedList)
    );
  }

  /**
   * Path part for operation quoteCreate
   */
  static readonly QuoteCreatePath = '/api/mobile/costcenter/{id}/quotes/';

  /**
   * Create a quote with some options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quoteCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  quoteCreate$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;
    context?: HttpContext;
    body: Quote;
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.QuoteCreatePath,
      'post'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Create a quote with some options.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `quoteCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  quoteCreate(params: {
    /**
     * The id of the cost center
     */
    id: number;
    context?: HttpContext;
    body: Quote;
  }): Observable<Quote> {
    return this.quoteCreate$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation quoteDelete
   */
  static readonly QuoteDeletePath = '/api/mobile/costcenter/quotes/{quoteId}';

  /**
   * Delete a quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quoteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  quoteDelete$Response(params: {
    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.QuoteDeletePath,
      'delete'
    );
    if (params) {
      rb.path('quoteId', params.quoteId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Delete a quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `quoteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  quoteDelete(params: {
    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<void> {
    return this.quoteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation quoteRetrive
   */
  static readonly QuoteRetrivePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}';

  /**
   * Get a quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quoteRetrive()` instead.
   *
   * This method doesn't expect any request body.
   */
  quoteRetrive$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;

    /**
     * Hides the Rejected answers from the options array
     */
    $hideRejected?: boolean;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.QuoteRetrivePath,
      'get'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
      rb.query('$hideRejected', params['$hideRejected'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Get a quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `quoteRetrive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  quoteRetrive(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;

    /**
     * Hides the Rejected answers from the options array
     */
    $hideRejected?: boolean;
    context?: HttpContext;
  }): Observable<Quote> {
    return this.quoteRetrive$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation quoteUpdate
   */
  static readonly QuoteUpdatePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}';

  /**
   * Update a quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quoteUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  quoteUpdate$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body: Quote;
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.QuoteUpdatePath,
      'put'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Update a quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `quoteUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  quoteUpdate(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body: Quote;
  }): Observable<Quote> {
    return this.quoteUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation draftQuoteDelete
   */
  static readonly DraftQuoteDeletePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}';

  /**
   * Delete a draft quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `draftQuoteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  draftQuoteDelete$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.DraftQuoteDeletePath,
      'delete'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Delete a draft quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `draftQuoteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  draftQuoteDelete(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<void> {
    return this.draftQuoteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation requestQuotePreapproval
   */
  static readonly RequestQuotePreapprovalPath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}/request-pre-approval';

  /**
   * Operator action to request the pre-approval, usually done by an operator.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestQuotePreapproval()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestQuotePreapproval$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.RequestQuotePreapprovalPath,
      'post'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Operator action to request the pre-approval, usually done by an operator.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `requestQuotePreapproval$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestQuotePreapproval(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<Quote> {
    return this.requestQuotePreapproval$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation preApproveQuote
   */
  static readonly PreApproveQuotePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}/pre-approve';

  /**
   * Caporeparto action to pre-approve a quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preApproveQuote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  preApproveQuote$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body: {
      options?: Array<OptionHandledWithComment>;
    };
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.PreApproveQuotePath,
      'post'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Caporeparto action to pre-approve a quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preApproveQuote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  preApproveQuote(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body: {
      options?: Array<OptionHandledWithComment>;
    };
  }): Observable<Quote> {
    return this.preApproveQuote$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation preRejectQuote
   */
  static readonly PreRejectQuotePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}/pre-reject';

  /**
   * Caporeparto action to pre-reject a quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `preRejectQuote()` instead.
   *
   * This method doesn't expect any request body.
   */
  preRejectQuote$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.PreRejectQuotePath,
      'post'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Caporeparto action to pre-reject a quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `preRejectQuote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  preRejectQuote(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<Quote> {
    return this.preRejectQuote$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation approveQuote
   */
  static readonly ApproveQuotePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}/approve';

  /**
   * Responsabile action to approve a quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveQuote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approveQuote$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body?: OptionHandledWithComment;
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.ApproveQuotePath,
      'post'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Responsabile action to approve a quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approveQuote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approveQuote(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body?: OptionHandledWithComment;
  }): Observable<Quote> {
    return this.approveQuote$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation rejectQuote
   */
  static readonly RejectQuotePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}/reject';

  /**
   * Caporeparto action to reject a quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectQuote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rejectQuote$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body?: {
      options?: Array<OptionHandledWithComment>;
    };
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.RejectQuotePath,
      'post'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Caporeparto action to reject a quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rejectQuote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rejectQuote(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body?: {
      options?: Array<OptionHandledWithComment>;
    };
  }): Observable<Quote> {
    return this.rejectQuote$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation acceptOverBudgetQuote
   */
  static readonly AcceptOverBudgetQuotePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}/accept-over-budget';

  /**
   * Supervisore action to accept an over budget quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptOverBudgetQuote()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptOverBudgetQuote$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.AcceptOverBudgetQuotePath,
      'post'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Supervisore action to accept an over budget quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acceptOverBudgetQuote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptOverBudgetQuote(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<Quote> {
    return this.acceptOverBudgetQuote$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation rejectOverBudgetQuote
   */
  static readonly RejectOverBudgetQuotePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}/reject-over-budget';

  /**
   * Supervisore action to reject an over budget quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectOverBudgetQuote()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectOverBudgetQuote$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.RejectOverBudgetQuotePath,
      'post'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Supervisore action to reject an over budget quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rejectOverBudgetQuote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectOverBudgetQuote(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<Quote> {
    return this.rejectOverBudgetQuote$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation assignBudgetVoiceToQuote
   */
  static readonly AssignBudgetVoiceToQuotePath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}/assign-budget-voice/';

  /**
   * Caporeparto action to approve a quote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignBudgetVoiceToQuote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignBudgetVoiceToQuote$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body: {
      budgetItem?: number;
    };
  }): Observable<StrictHttpResponse<Quote>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.AssignBudgetVoiceToQuotePath,
      'put'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Quote>;
        })
      );
  }

  /**
   * Caporeparto action to approve a quote.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignBudgetVoiceToQuote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignBudgetVoiceToQuote(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
    body: {
      budgetItem?: number;
    };
  }): Observable<Quote> {
    return this.assignBudgetVoiceToQuote$Response(params).pipe(
      map((r: StrictHttpResponse<Quote>) => r.body as Quote)
    );
  }

  /**
   * Path part for operation getQuoteHistory
   */
  static readonly GetQuoteHistoryPath =
    '/api/mobile/costcenter/{id}/quotes/{quoteId}/history/';

  /**
   * Get history of quote changes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuoteHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteHistory$Response(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<StrictHttpResponse<Array<QuoteHistory>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      QuoteService.GetQuoteHistoryPath,
      'get'
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quoteId', params.quoteId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<QuoteHistory>>;
        })
      );
  }

  /**
   * Get history of quote changes.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuoteHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuoteHistory(params: {
    /**
     * The id of the cost center
     */
    id: number;

    /**
     * The id of the quote
     */
    quoteId: number;
    context?: HttpContext;
  }): Observable<Array<QuoteHistory>> {
    return this.getQuoteHistory$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<QuoteHistory>>) =>
          r.body as Array<QuoteHistory>
      )
    );
  }
}
