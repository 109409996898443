import { ERROR_EXPECTED_MISSING_VALUE } from './error-constants';

export class AppException extends Error {
  constructor(public errorCode: string) {
    super(errorCode);
  }
}

export function getValueOrError<T>(
  value: T | null | undefined,
  error = ERROR_EXPECTED_MISSING_VALUE
): T {
  if (value == null) {
    throw new AppException(error);
  }
  return value;
}
