<ion-header mode="ios">
  <ion-toolbar color="secondary">
    <ion-title class="text-grey-ultra-dark">{{ 'pages.quotes.detail.title' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button [attr.aria-label]="'pages.quotes.modal.cancel' | translate" (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ui-current-cdc [costCenter]="costCenter"></ui-current-cdc>
</ion-header>

<ion-content class="ion-padding">
  <ui-quote-detail
    #quoteDetails
    [showMacroBudgetItem]="true"
    [identity]="identity"
    [showStatus]="false"
    [budgetLimit]="budgetLimit"
    [showGenericOverBudgetWarning]="true"
    insertLabel="pages.quotes.detail.approved"
    [showBudgetLimit]="true"
    [detail]="_quote"
    [showApprovedDate]="true"
    [showBudgetItemBudget]="true"
    [showOverBudgetAmount]="true"
  >
  </ui-quote-detail>

  <form *appVar="this.form?.controls?.options?.at(quoteDetails.optionNumber - 1) as option" [formGroup]="option">
    <ui-approve-reject-toggle-buttons
      class="mt-32"
      [forceColour]="true"
      [approved]="option.controls.approved.value"
      [approveText]="'pages.approvals.approveOverbudget' | translate"
      [rejectText]="'pages.approvals.rejectOverbudget' | translate"
      (approvalResult)="onApprovalResult($event)"
    >
    </ui-approve-reject-toggle-buttons>
  </form>
</ion-content>
