import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserSummaryMacroItems } from '@libs/core/api';
import { from, switchMap, tap } from 'rxjs';
import { SelectCaporepartoModalComponent } from '../select-caporeparto-modal/select-caporeparto-modal.component';

@Component({
  selector: 'ui-select-caporeparto',
  templateUrl: './select-caporeparto.component.html',
  styleUrls: ['./select-caporeparto.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectCaporepartoComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCaporepartoComponent implements ControlValueAccessor {
  @Input() capireparto!: UserSummaryMacroItems[];

  value: UserSummaryMacroItems | undefined;

  onChange!: (value: UserSummaryMacroItems | undefined) => void;
  onTouched!: (value: UserSummaryMacroItems | undefined) => void;

  constructor(
    private changeRef: ChangeDetectorRef,
    private modalController: ModalController
  ) {}

  writeValue(value: UserSummaryMacroItems | undefined): void {
    this.value = value;
    this.changeRef.detectChanges();
  }

  registerOnChange(
    fn: (value: UserSummaryMacroItems | undefined) => void
  ): void {
    this.onChange = fn;
  }

  registerOnTouched(
    fn: (value: UserSummaryMacroItems | undefined) => void
  ): void {
    this.onTouched = fn;
  }

  openCaporepartoSelection() {
    from(
      this.modalController.create({
        component: SelectCaporepartoModalComponent,
        mode: 'md',
        componentProps: {
          capireparto: this.capireparto,
          caporeparto: this.value,
        },
      })
    )
      .pipe(
        tap((modal) => modal.present()),
        switchMap((modal) => from(modal.onDidDismiss<UserSummaryMacroItems>()))
      )
      .subscribe((event) => {
        if (event.data) {
          this.writeValue(event.data);
          this.onChange(event.data);
        }
      });
  }
}
