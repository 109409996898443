import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BudgetItemSummary, BudgetMacroItemComplete } from '@libs/core/api';
import { getValueOrError } from '@front-end/core/utils';
import { ModalController } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { IonicBaseComponent } from '@front-end/core/ionic-hooks';
import { CheckboxListOption } from '../checkbox-list/abstract-checkbox-list.component';

@Component({
  selector: 'ui-budget-item-select-modal',
  templateUrl: './budget-item-select-modal.component.html',
  styleUrls: ['./budget-item-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetItemSelectModalComponent extends IonicBaseComponent {
  readonly budgetItemFormControl = this.formBuilder.control<
    BudgetItemSummary[]
  >([]);
  readonly TOTAL_BUDGET = 'totalBudget';
  readonly RESIDUAL_BUDGET = 'residualBudget';
  readonly USED_BUDGET = 'usedBudget';

  _macroBudgetItems: CheckboxListOption[] = [];
  @Input() set macroItems(value: BudgetMacroItemComplete[]) {
    this._macroBudgetItems = value.map((macroBudgetItem) => ({
      label: getValueOrError(macroBudgetItem.name),
      options: (macroBudgetItem.budgetItems ?? []).map((budgetItem) => ({
        label: getValueOrError(budgetItem.name),
        value: budgetItem,
      })),
    }));
  }

  @Input() set selected(value: BudgetItemSummary | undefined) {
    this.budgetItemFormControl.setValue(value ? [value] : []);
  }

  filterProperty = '';

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  confirm(): void {
    this.modalController.dismiss(this.budgetItemFormControl.value, 'confirm');
  }

  close(): void {
    this.modalController.dismiss();
  }

  filterFor(totalBudget: string) {
    if (totalBudget === this.filterProperty) {
      this.filterProperty = '';
    } else {
      this.filterProperty = totalBudget;
    }
  }
}
