import { NgModule } from '@angular/core';
import {
  LoadChildren,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { environment } from '../environments/environment';
import { AuthUserGuard } from 'ngx-auth-utils';

const devRoutes: Routes = environment.enableDevModule
  ? [
      {
        path: 'dev',
        loadChildren: (() =>
          import('@libs/dev').then((m) => m.DevModule)) as LoadChildren,
      },
    ]
  : [];

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthUserGuard],
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'user',
  },
  ...devRoutes,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
