<div *ngIf="file">
  <ng-container [ngSwitch]="file.type">
    <ng-container *ngSwitchCase="'image'">
      <button *ngIf="preview; else image" class="absolute w-full h-full p-0" (click)="openSlider()">
        <ng-container *ngTemplateOutlet="image"></ng-container>
      </button>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div *ngIf="preview && file.downloadable && !isNative; else document" class="absolute w-full h-full text-grey-dark">
        <ng-container *ngTemplateOutlet="document"></ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #document>
    <div class="grid place-content-center w-full h-full" (click)="openSlider()">
      <pdf-viewer
        [src]="file.data"
        [render-text]="false"
        [original-size]="false"
        [show-all]="false"
        [zoom-scale]="'page-height'"
        [autoresize]="true"
        (after-load-complete)="afterLoadComplete()"
      ></pdf-viewer>
    </div>
  </ng-template>

  <ng-template #image>
    <img alt="Preview" class="image w-full h-full" [src]="file.data" />
  </ng-template>
</div>
