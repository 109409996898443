import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BudgetMacroItem,
  Identity,
  Quote,
  QuoteOption,
  QuoteStatusEnum,
} from '@libs/core/api';
import { CaporepartoApprovals } from '../caporeparto-quote-detail-modal/caporeparto-quote-detail-modal.component';
import { SegmentEvent } from '../quote-options-tab/quote-options-tab.component';
import { ModalController } from '@ionic/angular';
import { from, switchMap } from 'rxjs';
import { ImageSliderModalComponent } from '../image-slider-modal/image-slider-modal.component';
import { QuoteHistoryModalComponent } from '../quote-history-modal/quote-history-modal.component';

@Component({
  selector: 'ui-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteDetailComponent {
  @Input() identity!: Identity;

  @Input() set detail(quote: Quote) {
    this.quote = quote;
    this.option = quote?.options?.[0];

    if (this.quote.budgetItem?.macroItem != null) {
      this.macroItem = this.quote.budgetItem?.macroItem;
    } else if (quote.assignee?.budgetMacroItems?.length === 1) {
      this.macroItem = quote.assignee?.budgetMacroItems?.[0];
    }

    this.showCreator = ![
      QuoteStatusEnum.PreRejected,
      QuoteStatusEnum.Rejected,
      QuoteStatusEnum.OverBudgetRejected,
    ].includes(this.quote.status as QuoteStatusEnum);
  }

  @Input() showApprovedDate = false;
  @Input() showStatus = true;
  @Input() showMacroBudgetItem = true;
  @Input() budgetLimit?: number | null;
  @Input() caporepartoApprovals?: Array<CaporepartoApprovals>;
  @Input() showGenericOverBudgetWarning = false;
  @Input() insertLabel = 'pages.quotes.detail.created';
  @Input() showBudgetLimit = false;
  @Input() showBudgetItemBudget = false;
  @Input() showOverBudgetAmount = false;

  constructor(private modalController: ModalController) {}

  quote!: Quote;
  option!: QuoteOption;
  optionNumber = 1;
  macroItem?: BudgetMacroItem;
  showCreator = true;

  changeOption(event: SegmentEvent) {
    this.option = this.quote.options[Number(event.detail.value)];
    this.optionNumber = Number(event.detail.value) + 1;
  }

  openSlider($event: string) {
    from(
      this.modalController.create({
        component: ImageSliderModalComponent,
        mode: 'md',
        componentProps: {
          attachments: this.option.attachments,
          selectedAttachment: $event,
        },
      })
    ).subscribe((modal) => modal.present());
  }

  openHistory() {
    from(
      this.modalController.create({
        component: QuoteHistoryModalComponent,
        mode: 'md',
        componentProps: {
          quote: this.quote,
        },
      })
    )
      .pipe(switchMap((modal) => from(modal.present())))
      .subscribe();
  }
}
