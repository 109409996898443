import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { of } from 'rxjs';

export function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      const res = reader.result as string;
      resolve(res);
    };
  });
}

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64.split(',')[1]);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return of(bytes);
}

export async function saveAndOpenPdfReport(
  data: Blob | string,
  fileName: string = defaultFileName()
) {
  const res = await Filesystem.writeFile({
    path: fileName,
    data: data instanceof Blob ? await blobToBase64(data) : data,
    directory: Directory.Data,
  });

  const fileOpener = new FileOpener();

  await fileOpener.open(res.uri, 'application/pdf');
}

function defaultFileName() {
  const timestamp = new Date().getTime();
  return `stp-${timestamp}.pdf`;
}

export function getFileType(data: string) {
  if (data.startsWith('data:')) {
    return data.includes('image') ? 'image' : 'document';
  } else if (data.startsWith('https:')) {
    return data.endsWith('.pdf') ? 'document' : 'image';
  }
  return 'image';
}
