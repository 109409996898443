import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RoleEnum, UserService, UserSummaryMacroItems } from '@libs/core/api';
import { first, from, map, switchMap } from 'rxjs';
import { AnimationController, ModalController } from '@ionic/angular';
import {
  QuoteFiltersConfiguration,
  QuotesFiltersModalComponent,
} from '../quotes-filters-modal/quotes-filters-modal.component';
import { getValueOrError } from '@front-end/core/utils';
import { FormBuilder } from '@angular/forms';
import {
  ApiFiltersService,
  CostCentersService,
} from '@front-end/core/services';
import { LoadingService, ToastService } from '../../services';
import { tap } from 'rxjs/operators';
import { AnimationsService } from '../../services/animations.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export enum DateOptionEnum {
  LAST_WEEK,
  LAST_MONTH,
  LAST_SEMESTER,
  ALL,
}

export enum QuoteStatusFilterEnum {
  Draft = 'Draft',
  WaitingForApproval = 'WaitingForPreApproval',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface QuoteFilters {
  toggle?: boolean;
  status?: (QuoteStatusFilterEnum | undefined)[];
  date?: (DateOptionEnum | undefined)[];
  caporeparti?: (UserSummaryMacroItems | undefined)[];
}

@UntilDestroy()
@Component({
  selector: 'ui-quotes-filter',
  templateUrl: './quotes-filters.component.html',
  styleUrls: ['./quotes-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotesFiltersComponent {
  @Input() toggleLabel!: string;
  @Input() caporeparti: UserSummaryMacroItems[] = [];
  @Input() set filters(filters: QuoteFilters) {
    this.form.patchValue(filters, { emitEvent: false });
  }
  @Input() configuration: QuoteFiltersConfiguration = {
    toggle: false,
    status: true,
    date: true,
    caporeparti: true,
  };
  @Output() filtersChanged = new EventEmitter<QuoteFilters>();

  readonly form = this.formBuilder.group({
    toggle: this.formBuilder.control<boolean>(false),
    status: this.formBuilder.control<(QuoteStatusFilterEnum | undefined)[]>([
      undefined,
    ]),
    date: this.formBuilder.control<(DateOptionEnum | undefined)[]>([
      DateOptionEnum.LAST_WEEK,
    ]),
    caporeparti: this.formBuilder.control<
      (UserSummaryMacroItems | undefined)[]
    >([undefined]),
  });

  readonly formUpdate$ = this.form.valueChanges.pipe(
    untilDestroyed(this),
    tap(() => this.filtersChanged.emit(this.form.value as QuoteFilters))
  );

  constructor(
    private modalController: ModalController,
    private animationController: AnimationController,
    private userService: UserService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private apiFiltersService: ApiFiltersService,
    private costCentersService: CostCentersService,
    private animationService: AnimationsService
  ) {}

  openFiltersModal(): void {
    this.costCentersService.selectedCostCenter$
      .pipe(
        first(),
        switchMap((costCenter) =>
          this.userService.costCenterUserList({
            id: getValueOrError(costCenter.id),
            $filter: this.apiFiltersService.getUserRoleFilter(
              RoleEnum.Caporeparto
            ),
            $myMacroItems: [RoleEnum.Operatore, RoleEnum.Caporeparto].includes(
              costCenter.role as RoleEnum
            ),
          })
        ),
        this.loadingService.withLoading(),
        this.toastService.catchErrorWithToast(),
        switchMap((users) =>
          from(
            this.modalController.create({
              component: QuotesFiltersModalComponent,
              componentProps: {
                capoReparti: users,
                filters: this.form.value,
                configuration: this.configuration,
              },
              enterAnimation: this.animationService.slideLeftToRight,
              leaveAnimation: this.animationService.slideRightToLeft,
            })
          )
        ),
        switchMap((modal) => from(modal.present()).pipe(map(() => modal))),
        switchMap((modal) => from(modal.onWillDismiss()))
      )
      .subscribe(({ data }) => this.form.patchValue(data));
  }
}
