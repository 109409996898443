import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import {
  CostCenterSummary,
  Identity,
  OptionHandledWithComment,
  Quote,
  QuoteOption,
} from '@libs/core/api';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastService } from '../../services';
import { from, map, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

interface WaitingForPreApprovalQuoteOptionForm {
  id: FormControl<number | null>;
  comment: FormControl<string | null | undefined>;
  approved: FormControl<boolean | null | undefined>;
}

export interface CaporepartoApprovals {
  approved?: boolean;
}
export interface CaporepartoModalOutput {
  preApprovedOptions: Array<OptionHandledWithComment>;
}

@Component({
  selector: 'ui-caporeparto-quote-detail-modal',
  templateUrl: './caporeparto-quote-detail-modal.component.html',
  styleUrls: ['./caporeparto-quote-detail-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaporepartoQuoteDetailModalComponent implements OnInit {
  @Input() costCenter!: CostCenterSummary;
  @Input() quote!: Quote;
  @Input() identity!: Identity;

  public form?: FormGroup<{
    options: FormArray<FormGroup<WaitingForPreApprovalQuoteOptionForm>>;
  }>;

  caporepartoApprovals?: Array<CaporepartoApprovals>;

  optionsToApprove = 0;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private alertController: AlertController,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      options: new FormArray<FormGroup<WaitingForPreApprovalQuoteOptionForm>>(
        this.quote.options.map((option: QuoteOption) => {
          return this.formBuilder.group<WaitingForPreApprovalQuoteOptionForm>({
            id: this.formBuilder.control<number>(option.id || 0, [
              Validators.required,
            ]),
            comment: this.formBuilder.control<string | undefined>(''),
            approved: this.formBuilder.control<boolean | undefined>(undefined, [
              Validators.required,
            ]),
          });
        })
      ),
    });

    this.optionsToApprove = this.quote.options.length;

    this.caporepartoApprovals = this.quote.options.map(() => ({
      approved: undefined,
    }));

    this.form.controls.options.valueChanges.subscribe(() => {
      this.optionsToApprove =
        this.form?.controls?.options?.value?.filter(
          (option) => option?.approved == null
        )?.length || 0;
    });
  }

  cancel() {
    this.modalController.dismiss(undefined, 'cancel');
  }

  confirm(): void {
    if (this.form?.invalid) {
      from(
        this.alertController.create({
          header: this.translateService.instant(
            'pages.approvals.missingOptionApprovalAlert.header'
          ),
          message: this.translateService.instant(
            'pages.approvals.missingOptionApprovalAlert.message'
          ),
          buttons: [
            this.translateService.instant(
              'pages.approvals.missingOptionApprovalAlert.confirm'
            ),
          ],
        })
      )
        .pipe(
          switchMap((alert) => from(alert.present()).pipe(map(() => alert))),
          switchMap((alert) => from(alert.onWillDismiss()))
        )
        .subscribe();
    } else {
      const approvedOptions =
        this.form?.value.options?.filter(
          (option) => option.approved === true
        ) ?? [];
      this.modalController.dismiss(
        { preApprovedOptions: approvedOptions },
        approvedOptions.length > 0 ? 'pre-approve' : 'pre-reject'
      );
    }
  }

  onApprovalResult(
    $event: boolean,
    option: FormGroup<WaitingForPreApprovalQuoteOptionForm>,
    optionNumber: number
  ) {
    option.controls.approved.setValue($event);
    if (this.caporepartoApprovals) {
      this.caporepartoApprovals[optionNumber].approved = $event;
      this.caporepartoApprovals = [...this.caporepartoApprovals];
    }
  }
}
