/* tslint:disable */
/* eslint-disable */
export enum QuoteStatusEnum {
  Draft = 'Draft',
  WaitingForPreApproval = 'WaitingForPreApproval',
  PreApproved = 'PreApproved',
  PreRejected = 'PreRejected',
  Approved = 'Approved',
  Rejected = 'Rejected',
  ApprovedOverBudget = 'ApprovedOverBudget',
  OverBudgetAccepted = 'OverBudgetAccepted',
  OverBudgetRejected = 'OverBudgetRejected',
}
