<ion-item-group [formGroup]="form">
  <ion-item class="mb-12" [class.error]="!form.controls.attachments.pristine && form.controls.attachments.invalid" lines="none">
    <div class="scroller">
      <div class="attachments gap-wrapper">
        <div
          class="attachment"
          *ngFor="let attachment of form.controls.attachments.controls; let i = index"
          (click)="openSlider(attachment.value)"
        >
          <div class="no-overflow">
            <ui-media-preview [fileUrl]="attachment.value"> </ui-media-preview>
          </div>

          <ion-button color="danger" (click)="removeAttachment(i)" mode="ios">
            <ion-icon name="close-outline" size="big"></ion-icon>
          </ion-button>
        </div>

        <ion-button #placeholder class="placeholder m-0" color="medium" fill="clear" (click)="uploader.click()">
          <ion-icon name="add-circle-outline"></ion-icon>
        </ion-button>

        <input
          #uploader
          data-cy="Attachment"
          class="hidden"
          type="file"
          accept="image/*,.pdf"
          (change)="onChangeAttachment($event)"
          required
        />
      </div>
    </div>
  </ion-item>

  <ion-item [class.error]="!form.controls.price.pristine && form.controls.price.invalid">
    <div class="flex flex-row w-full">
      <div class="flex-grow-1">
        <ion-label position="floating" color="medium">{{ 'pages.insert.form.price.label' | translate }}</ion-label>
        <ion-input [formControl]="form.controls.price" type="number" required data-cy="Price" min="0"></ion-input>
      </div>

      <ion-label class="align-self-end py-8">€</ion-label>
    </div>
  </ion-item>

  <ui-form-field [control]="form.controls.notes" label="pages.insert.form.notes" data-cy="Notes">
    <ion-textarea [formControl]="form.controls.notes" [autoGrow]="true" [rows]="1"></ion-textarea>
  </ui-form-field>
</ion-item-group>
