import { AccessTokenModel, AuthenticationProvider } from 'ngx-auth-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthTokens } from '../models/auth-tokens';
import { getValueOrError } from '@front-end/core/utils';
import { Identity } from '../models/identity';
import { AuthService } from '../services/auth.service';
import { TokenLoginRequest } from '../models/token-login-request';
import { StrictHttpResponse } from '../strict-http-response';

export class BackendAuthenticationProvider extends AuthenticationProvider {
  constructor(private authService: AuthService) {
    super();
  }

  private static mapAccessToken(
    tokenPair: StrictHttpResponse<AuthTokens>
  ): AccessTokenModel {
    {
      return {
        accessToken: getValueOrError(tokenPair.body.access_token),
        refreshToken: tokenPair.headers.get('Stp-Refresh-Token') ?? undefined,
      };
    }
  }

  fetchUser(): Observable<Identity> {
    return this.authService.identity();
  }

  doLogin(credentials: TokenLoginRequest): Observable<AccessTokenModel> {
    return this.authService
      .login$Response({ body: credentials })
      .pipe(map(BackendAuthenticationProvider.mapAccessToken));
  }

  override refreshToken(
    accessToken: string,
    refreshToken: string
  ): Observable<AccessTokenModel> {
    return this.authService
      .login$Response({
        body: {
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: 'TeleconsultingRDA',
        },
        'Stp-Refresh-Token': refreshToken,
      })
      .pipe(map(BackendAuthenticationProvider.mapAccessToken));
  }
}
