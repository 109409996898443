import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'front-end-insert-confirm-screen',
  templateUrl: './insert-confirm-screen.component.html',
  styleUrls: ['./insert-confirm-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertConfirmScreenComponent {
  @Input() icon!: string;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() action!: string;
  @Input() actionId!: string;
  @Output() actionClick: EventEmitter<void> = new EventEmitter();
}
