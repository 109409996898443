import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  CostCenterSummary,
  Identity,
  Quote,
  QuoteOption,
} from '@libs/core/api';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

interface SupervisoreApprovalQuoteOption {
  id: FormControl<number | null>;
  approved: FormControl<boolean | null | undefined>;
}

export interface SupervisoreApprovals {
  approved?: boolean;
}

export interface SupervisoreModalOutput {
  isApproved: boolean;
}

@Component({
  selector: 'ui-supervisore-quote-detail-modal',
  templateUrl: './supervisore-quote-detail-modal.component.html',
  styleUrls: ['./supervisore-quote-detail-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupervisoreQuoteDetailModalComponent implements OnInit {
  @Input() costCenter!: CostCenterSummary;
  @Input() set quote(quote: Quote) {
    this._quote = quote;
  }
  _quote!: Quote;
  @Input() identity!: Identity;
  @Input() budgetLimit?: number | null;

  public form?: FormGroup<{
    options: FormArray<FormGroup<SupervisoreApprovalQuoteOption>>;
  }>;

  supervisoreApprovals?: Array<SupervisoreApprovals>;
  optionsToApprove = 0;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      options: new FormArray<FormGroup<SupervisoreApprovalQuoteOption>>(
        this._quote.options.map((option: QuoteOption) => {
          return this.formBuilder.group<SupervisoreApprovalQuoteOption>({
            id: this.formBuilder.control<number>(option.id || 0, [
              Validators.required,
            ]),
            approved: this.formBuilder.control<boolean | undefined>(undefined, [
              Validators.required,
            ]),
          });
        })
      ),
    });
    this.optionsToApprove = this._quote.options.length;
    this.supervisoreApprovals = this._quote.options.map(() => ({
      approved: undefined,
    }));
  }

  cancel() {
    this.modalController.dismiss(undefined, 'cancel');
  }

  onApprovalResult($event: boolean) {
    this.modalController.dismiss(
      { isApproved: $event },
      'over-budget-for-approval'
    );
  }
}
