<ion-content color="primary">
  <div class="flex align-items-center justify-content-center m-0 w-full h-full relative p-16">
    <div class="text-container flex flex-column align-items-center">
      <div class="shadow-icon flex flex-column justify-content-center align-items-center">
        <ion-icon [name]="icon"></ion-icon>
      </div>

      <h1 class="fs-24 font-medium text-center mb-0">{{ title }}</h1>
      <p class="fs-14 font-medium text-center">{{ subtitle }}</p>
    </div>

    <ion-button class="absolute x-16 b-16 font-medium" color="secondary" (click)="actionClick.emit()" [attr.data-cy]="actionId" mode="ios">
      {{ action | translate }}
    </ion-button>
  </div>
</ion-content>
