<ion-header class="ion-no-border" mode="ios">
  <ion-toolbar class="ion-no-padding">
    <ion-button class="ion-no-padding" slot="start" fill="clear" size="small" (click)="close()">
      <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
    </ion-button>
    <ion-title class="fs-18 font-medium">{{ 'pages.insert.form.budgetItemModal.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-no-padding">
  <div class="ion-flex ion-justify-content-between ion-align-items-center ion-padding">
    <span class="label-min-content">{{ 'pages.insert.form.budgetItemModal.filters.title' | translate }}</span>
    <div data-cy="Budget item filters">
      <ion-button size="small" [color]="filterProperty === TOTAL_BUDGET ? 'primary' : 'inactive'" (click)="filterFor(TOTAL_BUDGET)">
        {{ 'pages.insert.form.budgetItemModal.filters.total' | translate }}
      </ion-button>
      <ion-button size="small" [color]="filterProperty === RESIDUAL_BUDGET ? 'primary' : 'inactive'" (click)="filterFor(RESIDUAL_BUDGET)">
        {{ 'pages.insert.form.budgetItemModal.filters.residual' | translate }}
      </ion-button>
      <ion-button size="small" [color]="filterProperty === USED_BUDGET ? 'primary' : 'inactive'" (click)="filterFor(USED_BUDGET)">
        {{ 'pages.insert.form.budgetItemModal.filters.approved' | translate }}
      </ion-button>
    </div>
  </div>
  <ui-accordion-checkbox-list [options]="_macroBudgetItems" [formControl]="budgetItemFormControl">
    <ng-template #label let-option>
      <ion-label>
        <div class="ion-flex ion-justify-content-between">
          <span class="font-regular truncate-text">{{ option.label | translate }}</span>
          <span class="font-regular" data-cy="Budget item amount">{{
            option.value[filterProperty] | fixBackendPrice | currency: 'EUR'
          }}</span>
        </div>
      </ion-label>
    </ng-template>
  </ui-accordion-checkbox-list>
</ion-content>

<ion-footer class="ion-no-border" mode="ios">
  <ion-toolbar>
    <ion-buttons>
      <ion-button color="primary" expand="block" fill="solid" class="w-full" data-cy="Accept budget item" (click)="confirm()">{{
        'quote.filters.accept' | translate
      }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
