import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixBackendDate',
})
export class FixBackendDatePipe implements PipeTransform {
  transform(value: string | undefined): string | undefined {
    if (value != null) {
      return value + 'Z';
    }
    return value;
  }
}
