import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AlertService } from './alert.service';
import {
  concat,
  filter,
  first,
  from,
  interval,
  map,
  Observable,
  tap,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private readonly UPDATE_CHECK_INTERVAL = 60 * 60 * 1000; // 1 hour

  constructor(
    private updates: SwUpdate,
    private alertService: AlertService,
    private translateService: TranslateService,
    appRef: ApplicationRef
  ) {
    if (updates.isEnabled) {
      const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable));
      const intervalCheck$ = interval(this.UPDATE_CHECK_INTERVAL);
      const intervalCheckAfterAppStabilized$ = concat(
        appIsStable$,
        intervalCheck$
      );

      intervalCheckAfterAppStabilized$.subscribe(() =>
        updates.checkForUpdate()
      );
    }
  }

  checkForUpdates(): Observable<void> {
    return this.updates.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      switchMap(() => this.showUpdateAlert()),
      map(() => undefined)
    );
  }

  update(): Observable<void> {
    return from(this.updates.activateUpdate()).pipe(
      tap(() => document.location.reload()),
      map(() => undefined)
    );
  }

  private showUpdateAlert(): Observable<boolean> {
    return this.alertService
      .showAlert(
        this.translateService.instant('pwa.alertUpdate.title'),
        this.translateService.instant('pwa.alertUpdate.message'),
        [this.translateService.instant('pwa.alertUpdate.confirm')]
      )
      .pipe(switchMap(() => from(this.updates.activateUpdate())));
  }
}
