import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { interval, Subject, take } from 'rxjs';
import { FileData } from '@front-end/core/services';
import { Capacitor } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
import { getFileType } from '@front-end/core/utils';

@Component({
  selector: 'ui-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss'],
  providers: [HttpClient],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPreviewComponent {
  file?: FileData;
  readonly isNative = Capacitor.isNativePlatform();
  readonly pdfLoaded$ = new Subject<boolean>();

  @Input() set fileUrl(value: string) {
    this.file = {
      type: getFileType(value),
      data: value,
      downloadable: true,
    } as FileData;

    if (this.file.type === 'document') {
      this.workaroundForPdf();
    }
  }

  @Input() preview?: boolean;
  @Output() openSliderEvent = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  openSlider() {
    this.openSliderEvent.emit();
  }

  afterLoadComplete() {
    this.pdfLoaded$.next(true);
  }

  private workaroundForPdf() {
    // There is an issue with the pdf viewer, with this we trigger the change detection when the pdf is loaded
    interval(50)
      .pipe(take(100))
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }
}
