import { Observable, repeatWhen, Subject, takeUntil } from 'rxjs';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { IIonicBaseComponent } from './ionic-base-component.interface';
import { Component, isDevMode } from '@angular/core';

@Component({
  template: '',
})
export abstract class IonicBaseComponent
  implements ViewWillEnter, ViewWillLeave, IIonicBaseComponent
{
  private readonly _viewWillEnter$ = new Subject<void>();
  private readonly _viewWillLeave$ = new Subject<void>();

  get viewWillEnter$(): Observable<void> {
    return this._viewWillEnter$.asObservable();
  }

  get viewWillLeave$(): Observable<void> {
    return this._viewWillLeave$.asObservable();
  }

  ionViewWillEnter(): void {
    this.debug('View will enter');
    this._viewWillEnter$.next();
  }

  ionViewWillLeave(): void {
    this.debug('View will leave');
    this._viewWillLeave$.next();
  }

  ionViewDidEnter(): void {
    this.debug('View did enter');
  }

  ionViewDidLeave(): void {
    this.debug('View did leave');
  }

  protected untilPageActive<T>(): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => {
      return source.pipe(
        takeUntil<T>(this._viewWillLeave$),
        repeatWhen(() => this._viewWillEnter$)
      );
    };
  }

  protected onViewWillEnter$(): Observable<void> {
    return this.viewWillEnter$;
  }

  private debug(message: string): void {
    if (isDevMode()) {
      // eslint-disable-next-line no-console,no-restricted-syntax
      console.info('IonicBaseComponent', message, this);
    }
  }
}
