import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  // https://toolstud.io/photo/filesize.php?imagewidth=2560&imageheight=2560
  private readonly maxImageSizePx = 2560;
  private readonly imageQuality = 0.8;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  compress(file: File): Observable<string> {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = this.document.createElement('img');
        img.src = reader.result as string;

        img.onload = () => {
          const canvas = this.document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > this.maxImageSizePx) {
              height *= this.maxImageSizePx / width;
              width = this.maxImageSizePx;
            }
          } else {
            if (height > this.maxImageSizePx) {
              width *= this.maxImageSizePx / height;
              height = this.maxImageSizePx;
            }
          }
          canvas.width = width;
          canvas.height = height;

          ctx?.drawImage(img, 0, 0, width, height);

          const base64 = canvas.toDataURL(file.type, this.imageQuality);
          observer.next(base64);
          observer.complete();
        };
      };
    });
  }

  read(file: File): Observable<string> {
    return new Observable((observer) => {
      const reader = new FileReader();
      const isPdf = file.type.startsWith('application/pdf');

      if (file.type.startsWith('image/') || isPdf) {
        reader.readAsDataURL(file);
      } else {
        // for images passed with type like "application/png"
        reader.readAsArrayBuffer(file);
      }

      reader.onload = () => {
        const document = reader.result as string;
        if (isPdf) {
          observer.next(document);
          observer.complete();
        } else {
          this.compress(file).subscribe((image) => {
            observer.next(image);
            observer.complete();
          });
        }
      };

      reader.onerror = (error) => observer.error(error);
    });
  }

  write(data: string, title: string, type: string): Observable<File> {
    return new Observable((observer) => {
      const binary = atob(data);
      const buffer = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        buffer[i] = binary.charCodeAt(i);
      }

      observer.next(new File([buffer], title, { type }));
      observer.complete();
    });
  }
}
