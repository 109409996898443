import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  CostCentersService,
  PwaService,
  SendIntentService,
} from '@front-end/core/services';
import { MenuController, ModalController } from '@ionic/angular';
import { CostCenterSummary, RoleEnum } from '@libs/core/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NgxAuthService } from 'ngx-auth-utils';
import {
  EMPTY,
  catchError,
  debounceTime,
  first,
  map,
  switchMap,
  timer,
} from 'rxjs';
import { environment } from '../environments/environment';
import { NotificationService } from './quotes/notification.service';

@UntilDestroy()
@Component({
  selector: 'front-end-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
  readonly role$ = this.costCenterService.selectedCostCenter$.pipe(
    map((costCenter) => costCenter?.role)
  );
  readonly superOperatore$ = this.costCenterService.selectedCostCenter$.pipe(
    map((costCenter) => costCenter?.macroItems?.every((m) => m.superOperatore))
  );

  constructor(
    private authenticationService: NgxAuthService,
    private menuController: MenuController,
    private router: Router,
    private costCenterService: CostCentersService,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
    private updatePwaService: PwaService,
    private sendIntentService: SendIntentService,
    private notificationService: NotificationService
  ) {
    window.onpopstate = async () => {
      const modal = await this.modalCtrl.getTop();

      if (modal) {
        this.modalCtrl.dismiss();
        history.go(1);
        return;
      }
    };
  }

  readonly appVersion = environment.appVersion;

  currentYear = new Date().getFullYear();

  ngOnInit(): void {
    this.authenticationService.initialize().subscribe();

    this.updatePwaService
      .checkForUpdates()
      .pipe(
        debounceTime(1000),
        switchMap(() => this.updatePwaService.update()),
        untilDestroyed(this)
      )
      .subscribe();

    this.authenticationService.events.subscribe((event) => {
      if (event.type === 'login' || event.type === 'auto-login') {
        this.notificationService.setupPushNotifications();
      }
    });

    this.translateService.use('it');
    this.checkIntent();
  }

  ngAfterViewInit() {
    timer(2000).subscribe(() => SplashScreen.hide());
  }

  @HostListener('window:sendIntentReceived')
  onSendIntentReceived() {
    this.checkIntent();
  }

  checkIntent() {
    this.sendIntentService
      .checkSendIntentReceived()
      .pipe(
        first(),
        catchError(() => EMPTY)
      )
      .subscribe((intent) => {
        if (intent && intent.url) {
          this.sendIntentService.addIntent(intent);
        }
      });
  }

  logout() {
    this.closeMenu();
    this.notificationService.removePushNotificationToken();
    this.authenticationService.logout();
    this.router.navigate(['/user', 'login']);
  }

  closeMenu() {
    this.menuController.close();
  }

  selectCostCenter($event: CostCenterSummary) {
    this.costCenterService.selectCostCenter($event);
    this.closeMenu();

    if ($event.role === RoleEnum.Operatore) {
      this.router.navigate(['/home/insert']);
    } else if ($event.role === RoleEnum.Supervisore) {
      this.router.navigate(['/home/over-budget']);
    } else if ($event.role === RoleEnum.Caporeparto) {
      this.router.navigate(['/home/pre-approvals']);
    } else if ($event.role === RoleEnum.Responsabile) {
      this.router.navigate(['/home/approvals']);
    } else if ($event.role === RoleEnum.GodamAdministrator) {
      this.router.navigate(['/home/all-quotes']);
    }
  }
}
