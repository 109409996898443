<ion-header class="ion-no-border" mode="ios">
  <ion-toolbar class="ion-no-padding">
    <ion-button class="ion-no-padding" slot="start" fill="clear" size="small" (click)="close()">
      <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
    </ion-button>
    <ion-title class="fs-18 font-medium">{{ 'quote.filters.filter' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-no-padding">
  <ui-simple-checkbox-list
    *ngIf="configuration.status"
    data-cy="Status filter"
    [options]="statusOptions"
    [formControl]="form.controls.status"
    [multi]="true"
  ></ui-simple-checkbox-list>
  <ui-simple-checkbox-list
    *ngIf="configuration.date"
    data-cy="Date filter"
    [options]="dateOptions"
    [formControl]="form.controls.date"
  ></ui-simple-checkbox-list>
  <ui-simple-checkbox-list
    *ngIf="configuration.caporeparti"
    data-cy="Caporeparto filter"
    [options]="_capoRepartiOptions"
    [formControl]="form.controls.caporeparti"
    [multi]="true"
  ></ui-simple-checkbox-list>
</ion-content>

<ion-footer class="ion-no-border" mode="ios">
  <ion-toolbar>
    <ion-buttons>
      <ion-button color="primary" expand="block" fill="solid" class="w-full" data-cy="Accept quote filters" (click)="confirm()">{{
        'quote.filters.accept' | translate
      }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
