<form [id]="id" [formGroup]="form" (ngSubmit)="submit()">
  <ion-list>
    <ion-item [class.error]="!form.controls.description.pristine && form.controls.description.invalid">
      <ion-label position="floating" color="medium">{{ 'pages.insert.form.description.label' | translate }}</ion-label>
      <ion-input data-cy="Description" [formControl]="form.controls.description" type="text" required></ion-input>
    </ion-item>

    <ion-item [class.error]="!form.controls.assignee.pristine && form.controls.assignee.invalid" *ngIf="!showMacroItems && showAssignee">
      <ion-label position="floating" color="medium">{{ 'pages.insert.form.assignee.label' | translate }}</ion-label>

      <ui-select-caporeparto data-cy="Assignee" [formControl]="form.controls.assignee" [capireparto]="assignees"> </ui-select-caporeparto>
    </ion-item>

    <ion-item [class.error]="!form.controls.macroItem.pristine && form.controls.macroItem.invalid" *ngIf="hasMultipleMacroItems">
      <ion-label position="floating" color="medium">{{ 'pages.insert.form.macroItem.label' | translate }}</ion-label>

      <ui-select-macroitem data-cy="Macroitem" [formControl]="form.controls.macroItem" [macroItems]="costCenter.macroItems ?? []">
      </ui-select-macroitem>
    </ion-item>

    <ng-container *ngIf="canInsertBudgetItem">
      <ui-form-field
        [control]="form.controls.budgetItem"
        icon="chevron-forward"
        iconSlot="end"
        iconClasses="icon-end fs-16 pt-32"
        class="fs-16"
        lines="full"
        label="pages.approvals.details.budgetItem"
      >
        <ui-budget-item-select
          [formControl]="form.controls.budgetItem"
          [costCenter]="costCenter"
          [macroBudgetItems]="budgetMacroItems"
        ></ui-budget-item-select>
      </ui-form-field>
      <ui-budget-amounts
        class="mt-8 mb-28"
        *ngIf="form.controls.budgetItem.value"
        [budgetItem]="form.controls.budgetItem.value"
      ></ui-budget-amounts>
    </ng-container>

    <ion-item-group
      [ngClass]="{
        'mt-32': !hasAttachments,
        'mt-24': hasAttachments
      }"
    >
      <div
        class="flex flex-column"
        [ngClass]="{
          'column-gap-12': !hasAttachments,
          'column-gap-8': hasAttachments
        }"
      >
        <div class="flex flex-row align-items-center justify-content-space-between">
          <ion-label class="fs-medium text-uppercase">{{ 'pages.insert.form.details' | translate }}</ion-label>

          <ion-button
            *ngIf="(hasOptions || hasAttachments) && !canInsertBudgetItem"
            class="add-option fs-16 fs-medium m-0"
            data-cy="Add option"
            fill="clear"
            (click)="addOption()"
            mode="ios"
          >
            <ion-label>{{ 'pages.insert.form.addOption' | translate }}</ion-label>
            <ion-icon class="ml-4" name="grid-outline"></ion-icon>
          </ion-button>
        </div>

        <ui-quote-options-tab [options]="form.controls.options.controls" [option]="selectedOption" (optionChanged)="selectOption($event)">
        </ui-quote-options-tab>

        <front-end-insert-quote-form-option
          *ngIf="option"
          [form]="option"
          (openPreviewEvent)="openSlider($event)"
        ></front-end-insert-quote-form-option>

        <ion-button *ngIf="hasOptions" color="danger" fill="clear" (click)="removeOption(selectedOption)" mode="ios">
          <ion-label>{{ 'pages.insert.form.delete' | translate }}</ion-label>
        </ion-button>
      </div>
    </ion-item-group>
  </ion-list>
</form>
