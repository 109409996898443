<ion-header class="ion-no-border" mode="ios">
  <ion-toolbar color="dark">
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding" color="dark">
  <swiper #swiper [pagination]="{ type: 'fraction' }">
    <ng-template swiperSlide *ngFor="let attachment of attachments; index as i">
      <div class="inner">
        <ui-slider-item class="w-full" [fileUrl]="attachment"></ui-slider-item>
      </div>
    </ng-template>
  </swiper>
</ion-content>
